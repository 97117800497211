import React, { useContext, useEffect, useState } from "react";
import "./Dashboard.css";
import { Routes, Route, useNavigate } from "react-router-dom";
import { Button } from "@mui/material";
import LandingPage from "../../Panel/LandingPage/LandingPage";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import Tooltip from "@mui/material/Tooltip";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Navbar from "../../Components/Navbar/Navbar";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import clsx from "clsx";
import IconButton from "@material-ui/core/IconButton";
import Drawer from "@material-ui/core/Drawer";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import Collapse from "@material-ui/core/Collapse";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import dashImg from "../../Assets/Dashboard.png";
import allSessions from "../../Assets/allSessions.png";
import sessionRequest from "../../Assets/sessionRequest.png";
import studentImg from "../../Assets/studentImg.png";
import settingImg from "../../Assets/settingImg.png";
import lcarroLogo from "../../Assets/lcarroLogo.png";
import Home from "../Home/Home";
import SessionRequest from "../../Panel/SessionRequest/SessionRequest";
import Students from "../../Panel/Students/Students";
import SingleSession from "../../Components/SingleSession/SingleSession";
import TestFields from "../TestFields/TestFields";
import TestPagination from "../TestPagination/TestPagination";
import Setting from "../../Panel/Setting/Setting";
import { useLocation } from 'react-router-dom';



const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    padding: "0px",
    marginLeft: drawerWidth,
  },
  drawer: {
    // width: drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    backgroundColor: "white",
    padding: theme.spacing(3),
  },
  drawerOpen1: {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose1: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: `calc(100% - ${theme.spacing(7) + 1})px`,
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${theme.spacing(7) + 1}px)`,
    },
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    background: `white`,
    color: "black",
  },
  drawerClose: {
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up("sm")]: {
      width: theme.spacing(9) + 1,
    },
    background: `white`,
  },
  toolbarWrapper: {
    "& .MuiToolbar-gutters": {
      paddingLeft: "0px !important",
      paddingRight: "0px !important",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

function Dashboard1(props) {

  const { user_state } = useContext(UserCredsContext);
  let navigate = useNavigate();
  let location = useLocation();

  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const [openList1, setOpenList1] = React.useState(true);
  const [openList2, setOpenList2] = React.useState(true);
  const [activeTab, setActiveTab] = React.useState(1);
  const [ids, setId] = useState(''|| localStorage.getItem("id"));
 
  const handleClick = (_type) => {
    if (_type === 0) {
      setOpenList1(!openList1);
    } else if (_type === 1) {
      setOpenList2(!openList2);
    }
  };
  const theme = useTheme();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawer = () => {
    setOpen(!open);
  };

  const redirectPanel = (param) => {       
    navigate(`/dashboard/${param.url}`);
    let f = (window.location.href).split("/")[5];
    //setId(f);

    localStorage.setItem("id", param.url )
       setId(param.url)

  };
 
  const clickDrawer = (_component, _id) => { };
  // console.log(drawerOptionsNested, "drawerOptionsNested")
  return (
    <div>
      <div className={classes.root}>
        <CssBaseline />
        <AppBar
          position="fixed"
          className={clsx(classes.drawer, {
            [classes.drawerOpen1]: open,
            [classes.drawerClose1]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen1]: open,
              [classes.drawerClose1]: !open,
            }),
          }}
          style={{ background: "white", color: "black", boxShadow: "none" }}
        >
          <div style={{ width: "100%" }}>
            <Navbar />
          </div>
          <Divider />
        </AppBar>
        <Drawer
          variant="permanent"
          className={clsx(classes.drawer, {
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          })}
          classes={{
            paper: clsx({
              [classes.drawerOpen]: open,
              [classes.drawerClose]: !open,
            }),
          }}
        >
          <div className={classes.toolbar}>
            {open && <img src={lcarroLogo}  style ={{width: "8.75rem"}}   />}
            <IconButton onClick={handleDrawer}>
              {!open ? (
                <ChevronRightIcon
                  style={{
                    color: "black",
                    border: "1px solid black",
                    borderRadius: "50px",
                  }}
                />
              ) : (
                <ChevronLeftIcon
                  style={{
                    color: "black",
                    border: "1px solid black",
                    borderRadius: "50px",
                  }}
                />
              )}
            </IconButton>
          </div>
          <div style={{ width: "100%" }}>
            <List style={{ width: "100%", overflowX: "hidden" }}>
              {drawerOptionsNested.map((item, idx) => {

                return (
                  <React.Fragment key={idx}>
                    <ListItem
                      button
                      key={idx}
                      //  className="listname-animation"
                      className={`listname-animation ${item?.url === ids ? "active-button " : ""}`}
                 
                      style={{
                        width: "100%",
                        whiteSpace: `${open ? "initial" : ""}`,
                        overflowX: "hidden",
                      }}
                      onClick={() => redirectPanel(item)}
                    // onClick={() => handleClick(idx)}
                    >
                      <ListItemIcon>
                        {idx % 2 === 0 ? (
                          <img src={item.img} style={{ width: "20px" }} />
                        ) : (
                          <img src={item.img} style={{ width: "20px" }} />
                        )}
                      </ListItemIcon>
                      <ListItemText
                        style={ item?.url === ids?{color: "white",  whiteSpace: "nowrap"}:{ color: "black ",  whiteSpace: "nowrap"}}
                        primary={item.title}
                        sx={{ maxWidth: "100%" }}
                      />
                    </ListItem>
                    {/* {openList1 && <Divider />} */}
                  </React.Fragment>
                );
              })}
            </List>
          </div>
          <Divider />
        </Drawer>

        <main className={classes.content} style={{ position: "relative" }}>
          <div style={{ padding: "20px 10px", minHeight: "90vh" }}>
            <div className={classes.toolbar} />
            <Routes>
              <Route exact strict path="home" element={<Home />} />
              <Route
                strict
                exact
                path="/session-request"
                element={<SessionRequest />}
              />
              <Route strict exact path="/students" element={<Students />} />
              <Route
                strict
                exact
                path="/all-sessions"
                element={<SingleSession />}
              />
              <Route
                strict
                exact
                path="/test-fields"
                element={<TestFields />}
              />
              <Route
                strict
                exact
                path="/test-pagination"
                element={<TestPagination />}
              />
              <Route strict exact path="/setting" element={<Setting />} />
            </Routes>
          </div>
        </main>
      </div>
    </div>
  );
}

export default Dashboard1;

const drawerOptionsNested = [
  {
    type_id: 0,
    img: dashImg,
    title: "Dashboard",
    url: "home",
  },
  {
    type_id: 1,
    img: allSessions,
    title: "All Sessions",
    url: "all-sessions",
  },
  {
    type_id: 2,
    img: sessionRequest,
    title: "Session Requests",
    url: "session-request",
  },
  {
    type_id: 3,
    img: studentImg,
    title: "Students",
    url: "students",
  },

  {
    list_type: "Notification List",
    type_id: 4,
    img: settingImg,
    title: "Settings",
    url: "setting",
  },
];
