import React, { useContext } from "react";
import styles from "./Navbar.module.css";
import NotificationsIcon from "@mui/icons-material/Notifications";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";
import { useNavigate } from "react-router-dom";


function Navbar() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const { logout, user_state } = useContext(UserCredsContext);

    const {first_name, last_name, avatar } =  user_state;

  
  let navigate = useNavigate();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleSettings  = ()  => {
  
      // navigate("/dashboard/setting");
       navigate("/dashboard/setting")
    
  }

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  <Badge
    anchorOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
  ></Badge>;
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          borderBottom: "2px solid #12878b",
          paddingBottom: "20px",
        }}
      className= {styles.navbar }


      >
        <Box
          sx={{
            marginTop: "20px",
            display: "flex",
            alignItems: "center",
            gap: "20px",
            marginRight: "50px",
          }}
        >
          {/* <Badge color="secondary" className={styles.bad}>
            <NotificationsIcon sx={{ width: "1.25rem" }} />
          </Badge> */}
          {/* box for the anchor effect  */}
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "12rem",
                alignItems: "center ",
                gap: 1,
              }}
              aria-describedby={id}
              variant="contained"
              onClick={handleClick}
            >
              <Avatar
                alt="Remy Sharp"
                src= {avatar?avatar: "" }
                sx={{ width: "2.938rem", minHeight: "2.938rem" }}
              />
              <Typography
                variant="h6"
                sx={{
                  width: "7.438rem",
                  fontSize: "14px",
                  fontWeight: "400",
                }}
              >
                {first_name + ' ' + last_name}
              </Typography>
            </Box>

            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              style={{ width: "100%" }}
            >
           
              <Typography
                sx={{ p: 2, cursor: "pointer" }}
                onClick={handleSettings } 
              >
                Settings
              </Typography>

              <Typography
                sx={{ p: 2, cursor: "pointer" }}
                onClick={() => logout()}
              >
                Logout
              </Typography>


            </Popover>

            {/* popover end here */}
          </Box>
        </Box>
      </Box>
    </div>
  );
}

export default Navbar;
