 export  const Fakedata =  [
    {
        "id": 77,
        "slot": {
            "id": 1542,
            "time_slot": {
                "id": 43,
                "commence": "17:00:00",
                "conclude": "20:00:00"
            },
            "join": false,
            "is_available": true,
            "price": 500.0,
            "session_name": "Counselingcsk",
            "color": "#313e6c",
            "is_instructor_alloted": true,
            "instructor": 181,
            "rejected_by_instructors": []
        },
        "user": {
            "id": 48,
            "first_name": "Yogeshhh",
            "last_name": "Kiratwad",
            "avatar": null,
            "gender_str": "",
            "access": null,
            "groups": [],
            "profile": {
                "id": 312,
                "board": {
                    "id": 24,
                    "title": "Madhya Pradesh State Board",
                    "provider_id": 10,
                    "ordering": null,
                    "user": 2
                },
                "grade": {
                    "id": 8,
                    "title": "Class XII - Commerce",
                    "provider_id": 26,
                    "type": 1,
                    "index": null,
                    "user": 2
                },
                "exam_info": [],
                "title": "Mr",
                "dob": null,
                "name": null,
                "stream": 4,
                "institute": null,
                "studies_in": [],
                "exam": []
            },
            "institute": null,
            "instructor": null,
            "last_login": null,
            "username": "9146916727",
            "email": "kiratwadyogesh@gmail.com",
            "is_active": true,
            "date_joined": "2021-03-12T14:35:00.114548",
            "phone": "9146916727",
            "isd_code": "91",
            "gender": null,
            "verified": true,
            "user_type": 1,
            "platform": null,
            "user_device": null,
            "user_os": null,
            "user_version": null,
            "institute_name": null,
            "city": null
        },
        "date": "2022-08-26"
    },



]

export const session_notesFake = {
    "session_field1" : "Very good boy",
    "session_field2" : "Smart Boy",
    


}


export const session  = [
    {
        "page": "1",
        "next_page": 2,
        "previous_page": null,
        "max_pages": 5,
        "total_count": 5,
        "count": 1,
        "data": [
            {
                "id": 77,
                "slot": {
                    "id": 1570,
                    "time_slot": {
                        "id": 54,
                        "commence": "01:09:07",
                        "conclude": "02:08:12"
                    },
                    "join": false,
                    "is_available": false,
                    "price": 100.0,
                    "session_name": "Demo Counseling Session2",
                    "color": "#313e6c",
                    "is_instructor_alloted": true,
                    "instructor": 410,
                    "rejected_by_instructors": []
                },
                "user": {
                    "id": 48,
                    "first_name": "string",
                    "last_name": "string",
                    "avatar": null,
                    "gender_str": "MALE",
                    "profile": {
                        "id": 312,
                        "board": {
                            "id": 17,
                            "title": "Bihar State Board",
                            "provider_id": 101,
                            "ordering": null,
                            "user": 2
                        },
                        "grade": {
                            "id": 21,
                            "title": "AFCAT",
                            "provider_id": 39,
                            "type": 1,
                            "index": 4,
                            "user": 2
                        },
                        "exam_info": [],
                        "title": "string",
                        "dob": "2020-04-20",
                        "name": null,
                        "stream": 4,
                        "institute": null,
                        "studies_in": [],
                        "exam": []
                    },
                    "address": [
                        {
                            "id": 40,
                            "line_1": "Floor",
                            "line_2": "10, The Hindu Friends Society Marg, Saraswati Baug, Natwar Nagar, Jogeshwari East, Mumbai, Maharashtra 400060, India",
                            "landmark": "",
                            "city": "Mumbai",
                            "state": "Maharashtra",
                            "zipcode": "400060",
                            "country": "India",
                            "lat": 19.129366936197027,
                            "long": 72.84970477223396,
                            "primary": true
                        }
                    ],
                    "guardian": [
                        {
                            "id": 267,
                            "first_name": "string",
                            "last_name": "string",
                            "phone": "9876543210",
                            "email": "string@abc.com",
                            "relationship": 1,
                            "occupation": null,
                            "education": null
                        },
                        {
                            "id": 255,
                            "first_name": "string",
                            "last_name": "string",
                            "phone": "9876543210",
                            "email": "string@abc.com",
                            "relationship": 1,
                            "occupation": "",
                            "education": ""
                        }
                    ],
                    "institute": null,
                    "sessions": [
                        {
                            "id": 1570,
                            "time_slot": {
                                "id": 54,
                                "commence": "01:09:07",
                                "conclude": "02:08:12"
                            },
                            "join": false,
                            "date": "03-09-2022",
                            "counsellor": "Yogin ",
                            "review": null,
                            "language": null,
                            "dream": null,
                            "reason_for_referral": "CAREER COUNSELLING",
                            "session_notes": null,
                            "is_available": false,
                            "price": 100.0,
                            "session_name": "Demo Counseling Session2",
                            "color": "#313e6c",
                            "is_instructor_alloted": true,
                            "instructor": 410,
                            "rejected_by_instructors": []
                        },
                        {
                            "id": 174,
                            "time_slot": {
                                "id": 25,
                                "commence": "10:00:00",
                                "conclude": "11:00:00"
                            },
                            "join": false,
                            "date": "08-09-2022",
                            "counsellor": "aaa bbb",
                            "review": null,
                            "language": null,
                            "dream": null,
                            "reason_for_referral": "CAREER COUNSELLING",
                            "session_notes": null,
                            "is_available": false,
                            "price": 500.0,
                            "session_name": "Counseling",
                            "color": "#313e6c",
                            "is_instructor_alloted": true,
                            "instructor": 181,
                            "rejected_by_instructors": []
                        },
                        {
                            "id": 793,
                            "time_slot": {
                                "id": 28,
                                "commence": "13:00:00",
                                "conclude": "14:00:00"
                            },
                            "join": false,
                            "date": "12-09-2022",
                            "counsellor": "aaa bbb",
                            "review": null,
                            "language": null,
                            "dream": null,
                            "reason_for_referral": "CAREER COUNSELLING",
                            "session_notes": null,
                            "is_available": false,
                            "price": 500.0,
                            "session_name": "Counseling",
                            "color": "#313e6c",
                            "is_instructor_alloted": false,
                            "instructor": 181,
                            "rejected_by_instructors": [
                                181
                            ]
                        },
                        {
                            "id": 1595,
                            "time_slot": {
                                "id": 71,
                                "commence": "17:00:05",
                                "conclude": "18:00:05"
                            },
                            "join": false,
                            "date": "12-09-2022",
                            "counsellor": "aaa bbb",
                            "review": null,
                            "language": null,
                            "dream": null,
                            "reason_for_referral": "CAREER COUNSELLING",
                            "session_notes": null,
                            "is_available": false,
                            "price": 500.0,
                            "session_name": "Sept12Session",
                            "color": "#313e6c",
                            "is_instructor_alloted": true,
                            "instructor": 181,
                            "rejected_by_instructors": []
                        }
                    ],
                    "last_login": null,
                    "username": "9146916727",
                    "email": "kiratwadyogesh@gmail.com",
                    "is_active": true,
                    "date_joined": "2021-03-12T14:35:00.114548",
                    "phone": "9146916727",
                    "isd_code": "91",
                    "gender": "1",
                    "verified": true,
                    "user_type": 1,
                    "platform": null,
                    "user_device": null,
                    "user_os": null,
                    "user_version": null,
                    "institute_name": null,
                    "city": null,
                    "groups": []
                },
                "date": "2022-09-03",
                "total_sessions": 4,
                "last_counseling": {
                    "date": "2022-09-08",
                    "counselor_name": "aaabbb"
                }
            }
        ]
    }
    

]