import {
  AppBar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  Stack,
  Toolbar,
  Typography,
  Item,
  Avatar,
  Skeleton,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { DataContext } from "../../ContextApis/DataContext/DataContext";
import styles from "./my-styles.module.css";
import SessionReqCard from "../../Components/SessionRequestCard/SessionReqCard";
import { token_api } from "../../Utils/Network";
import CustomSnackBar from "../../Components/CustomSnackBar/CustomSnackBar";
import { yearMonthDayFormat } from "../../Utils/Utils";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "./sessionRequest.css";
import Pagination from '@mui/material/Pagination';


function SessionRequest() {
  const [userData, setuserData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [date, setDate] = useState(null);
  

  const [snackbarData, setSnackbarData] = useState({
    message: "",
    open: false,
    responseType: "",
  });

  const [paginationData, setPaginationData] = useState(1);
  const [pageState, setPageState] = useState(1);
  

  useEffect(() => {
    getSessionRequestData(null);
  }, [pageState]);

  const { page, previous_page, max_pages, total_count, count , next_page } = paginationData;

  const getSessionRequestData = (_date) => {
    // console.log(_date);
    setLoading(true);
    var url_params = `?`;
    if (_date !== null) {
      const { month, day, year } = yearMonthDayFormat(_date);
      url_params = `${url_params}month=${month}&year=${year}&day=${day}`;
    }
    token_api
      .get(`/counseling/v2/all_slots/counselor_slots/${url_params}&page=${pageState}`)
      .then((response) => {
        console.log("www", response);
        setuserData(response.data.data);
        setPageState(response.data.page);
        setPaginationData(response.data);
        setLoading(false);
      });
  };

  const handleLoading = (responseType, _boolean) => {
    // console.log(responseType, _boolean, "responseType, _boolean");
    getSessionRequestData(date);
    var message =
      responseType === "success"
        ? _boolean
          ? `Session Request  Accepted`
          : "Session Request Rejected"
        : "Somthing went wrong";

    // console.log(responseType, message, "message is here");
    setSnackbarData({
      message,
      open: true,
      responseType: responseType,
    });
    const timeOut = setTimeout(handleOpen, 3000);
    function handleOpen() {
      setSnackbarData({
        message: "",
        open: false,
        responseType: "success",
      });
    }
  };
  // console.log(snackbarData, "snackbarData");
  const handleDate = (_date) => {
    setDate(_date);
    getSessionRequestData(_date);
  };

  return (
    <>
      <div style={{marginBottom:'20px'}}>
        <DatePicker
          selected={date}
          placeholderText="Select Date dd/mm/yy"
          dateFormat="dd/MM/yyyy"
          onChange={(date) => handleDate(date)}
        />
      </div>
      {loading && (
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div style={{ width: "30%" }}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
          <div style={{ width: "30%" }}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
          <div style={{ width: "30%" }}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        </div>
      )}
      {!loading && userData.length === 0 && (
        <p style={{ textAlign: "center", margin: "auto" }}>
          <b>No pending request to display</b>
        </p>
      )}
      <Box className={styles.container}>
        {userData.map((student, index) => {
             
      
          const { user, slot, date , total_sessions, last_counseling: {counselor_name}} = student;
               
  
        
               
          return (
            <SessionReqCard
              user={user}
              key={index}
              slot={slot}
              date= {date}
              reloadData={handleLoading}
              counselor_name= {counselor_name}
              total_sessions={total_sessions}
              studentId ={student.id}
               userData = {userData}
            />
          );
        })}
      </Box>
      <Pagination
          count={max_pages}
          size="large"
          variant="outlined"
          shape="rounded"
          onChange={(e, value) => setPageState(value)}
        />
      <CustomSnackBar
        message={snackbarData.message}
        openSnack={snackbarData.open}
        severity={snackbarData.responseType}
      />
      {/* </Box> */}
    </>
  );
}

export default SessionRequest;
