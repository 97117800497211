import React, { useEffect } from "react";
import SingleSession from "../SingleSession/SingleSession";
import styles from "../Drawer1/drawer1.module.css";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import EditSessionNotes from "../../Panel/Students/EditSessionNotes";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputBase,
  makeStyles,
  MenuItem,
  Paper,
  Rating,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Stack,
} from "@mui/material";
import { token_api } from "../../Utils/Network";
import EditFieldsForm from "../../Panel/Students/EditFieldsForm";
import styled from "@emotion/styled";
import { findObject } from "../../Utils/Utils";
import { editSection } from "../../Utils/Constants";
import { useState } from "react";
import CustomStudentProfile from "../CustomStudentProfile/CustomStudentProfile";
import CustomDreamCareer from "../CustomDreamCareer/CustomDreamCareer";
import CustomConcern from "../CustomConcern/CustomConcern";
import CustomJudgement from "../CustomJudgement/CustomJudgement";
import CustomRecommendation from "../CustomRecommendation/CustomRecommendation";
import CustomSessionNotes from "../CustomSessionNotes/CustomSessionNotes";
import CustomMindDreamCareer from "../CustomMindDreamCareer/CustomMindDreamCareer";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Drawer1 = ({
  isDrawerOpen,
  onClose = () => {},
  redata,
  filterdata,
  children,
  setIsDrawerOpen,
}) => {
  const [open, setOpen] = React.useState(false);
  const [sectionType, setSectionType] = useState(editSection[0]);
  const [editObject, setEditObject] = useState({});
  const [objForForm, setObjForForm] = React.useState({});
  const [testDet, setTestDet] = useState([]);
  const [addSNote, setAddSNote] = useState([]);
  const [sessionObject, setSessionObject] = useState({
    id: null,
    language: "",
    dream: "",
    reason_for_referral: "",
    session_notes: {},
  });

  console.log("fffilterData", filterdata);

  const [editNotesForm, setEditNotesForm] = useState({
    id: null,
    check: false,
  });
  const handleClickOpen = (string, objForEdit) => {
    console.log("new entry", objForEdit);
    setOpen(true);
    // // setStateForEdit(id);
    const retObj = findObject(editSection, string, "type");
    console.log("Ret", retObj);
    setSectionType(retObj);
    setEditObject(objForEdit);
    setObjForForm(user);
  };

  const handleSessionNotesEdit = (index, check, ses) => {
    console.log("entered", index);

    setEditNotesForm({
      ...editNotesForm,
      id: index,
      check,
    });
    setSessionObject({
      ...sessionObject,
      id: ses.id,
    });
  };

  const openTestPdf = (e, id, testtype) => {
    console.log("idpdf", id);
    console.log("typepdf", testtype);

    token_api
      .get(`/counseling/overall_result/?user_id=${id}&test_type=${testtype}`)
      .then((response) => {
        console.log(response.data.data.pdf);
        response.data.data.pdf
          ? window.open(response.data.data.pdf)
          : alert("You have not given the test");
      });
  };

  const handleAddNotes = (index, element, obj, user) => {
    console.log("Add notes", obj);
    console.log(index, "ind");
    console.log("eleAddNote33", element);
    console.log("userId", user);

    let id;

    let size = 0;
    if (obj.session_notes === null) {
      size = 0;
      console.log("size1", size);
    } else {
      size = Object.keys(obj.session_notes).length;
      console.log("size", size);
    }

    // var size = Object.keys(session_notesFake).length;

    let e = (
      <Card
        sx={{
          backgroundColor: "#FFFFFF",
          color: "#696984",
          border: "1px solid #7E7E7E",
          width: "90%",
          marginTop: "10px",
        }}
      >
        <CardContent>
          <Box>
            <Typography>
              {/* Session Field {size +1} */}
              Note {size + 1}
              {/* <EditIcon
                marginLeft="100%"
                onClick={() => {
                  handleSessionNotesEdit(size, true, obj);
                }}
              /> */}
              <EditSessionNotes id={obj.id} userId={user.id} add={true} />
            </Typography>
          </Box>
        </CardContent>
      </Card>
    );

    setAddSNote([
      ...addSNote,
      {
        id: obj.id,
        e,
      },
    ]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let abc = filterdata[0];
  console.log(abc, "new");

  const { user } = abc;
  // console.log("ooo", user);

  useEffect(() => {
    token_api
      .get(`/counseling/test_result/?user_id=${user.id}`)
      .then((response) => setTestDet(response.data));
  }, []);

  console.log("get", testDet);

  const { guardian, address, sessions } = user;

  return (
    <div>
      <Drawer anchor="right" open={isDrawerOpen} onClose={onClose}>
        {/* {children} */}

        <Box className={styles.moreInfo} role="presentation">
          <Box>
            <Box
              sx={{
                width: "90%",
                margin: "auto",
              }}
            >
              <Stack direction="row" sx={{ paddingBottom: "25px" }}>
                <Typography
                  sx={{
                    display: "inline-block",
                    fontSize: "1.25em",
                    color: "#696984",
                    marginLeft: "10px",
                    fontWeight: "bold",
                  }}
                >
                  {user?.first_name + " " + user?.last_name}
                </Typography>
                <Box
                  sx={{
                    position: "absolute",
                    right: "10px",
                    height: "20px",
                    width: "100px",
                  }}
                >
                  <div className={styles.iconStyles}>
                    {" "}
                    <EditIcon
                      sx={{
                        marginLeft: "40%",
                        fontSize: "17.5px",
                        display: "inline-block",
                        cursor: "pointer",
                      }}
                      onClick={() =>
                        handleClickOpen("userDetails", user.profile)
                      }
                    />
                    <CloseIcon
                      className={styles.pointer}
                      onClick={() => setIsDrawerOpen(false)}
                    />
                  </div>
                  <Stack direction="row" spacing={1}></Stack>
                </Box>
              </Stack>

              <Box
                sx={{
                  width: "95%",
                  margin: "auto",
                  color: "#696984",
                  fontSize: "1em",
                }}
              >
                <Stack direction="row" spacing={5}>
                  <Box>
                    <Typography>
                      Grade: {user?.profile?.grade?.title}
                    </Typography>
                    <Typography>Gender: {user?.gender_str}</Typography>
                  </Box>
                  <Box>
                    <Typography>
                      Institute:{" "}
                      {user?.institute ? <>{user.institute}</> : <>{"-"}</>}
                    </Typography>
                    <Typography>DOB: {user?.profile?.dob} </Typography>
                  </Box>
                </Stack>
              </Box>
            </Box>

            {/* Guardian info  */}

            <Box
              sx={{
                width: "28.5em",
                margin: "auto",
                color: "#696984",
              }}
            >
              <Typography
                sx={{
                  marginTop: "15px",
                  marginBottom: "15px",
                  fontSize: "1.125em",
                  color: "#696984",
                  fontWeight: "bold",
                }}
              >
                Guardian Info:{" "}
                {guardian.length == 0 ? (
                  <Typography>No Guardian Info available.</Typography>
                ) : null}
              </Typography>
              <Stack direction="column" spacing={3}>
                {guardian.map((g) => {
                  // console.log("gg", g);
                  return (
                    <Card
                      sx={{
                        backgroundColor: "#FFFFFF",
                        color: "#696984",
                        border: "1px solid #7E7E7E",
                        paddingTop: "5px",
                        width: "auto",
                      }}
                    >
                      <CardContent color="#696984" sx={{ paddingTop: "2px" }}>
                        <EditIcon
                          sx={{
                            marginLeft: "98%",
                            fontSize: "15px",
                            cursor: "pointer",
                          }}
                          onClick={() => handleClickOpen("guardianDetails", g)}
                        />
                        <Stack direction="row" spacing={4}>
                          <Box sx={{ width: "100%" }}>
                            <Stack direction="column" spacing={1}>
                              <Typography>
                                Name:{" "}
                                {g ? g.first_name + " " + g.last_name : "-"}
                              </Typography>
                              <Typography>
                                Email: {g.email ? g.email : "-"}
                              </Typography>
                              <Typography>
                                Education: {g.education ? g.education : "-"}
                              </Typography>
                            </Stack>
                          </Box>
                          <Box sx={{ width: "100%" }}>
                            <Stack direction="column" spacing={1}>
                              <Typography>
                                Mobile: {g.phone ? g.phone : "-"}
                              </Typography>
                              <Typography>
                                Occupation: {g.occupation ? g.occupation : "-"}
                              </Typography>
                            </Stack>
                          </Box>
                        </Stack>
                      </CardContent>
                    </Card>
                  );
                })}
              </Stack>
            </Box>

            {/* address components  */}
            <Box
              sx={{
                marginTop: "20px",
                width: "28.5em",
                margin: "auto",
                color: "#696984",
              }}
            >
              <Typography
                sx={{
                  display: "inline-block",
                  marginTop: "15px",
                  marginBottom: "15px",
                  fontSize: "1.125em",
                  color: "#696984",
                  fontWeight: "bold",
                }}
              >
                Address:{" "}
                {user.address.length == 0 ? (
                  <Typography>Adress not available.</Typography>
                ) : null}
              </Typography>
              {user.address.length == 0
                ? null
                : user.address.map((addEle) => {
                    return (
                      <Card
                        sx={{
                          backgroundColor: "#FFFFFF",
                          color: "#696984",
                          border: "1px solid #7E7E7E",
                          marginTop: "15px",
                        }}
                      >
                        <CardContent>
                          <EditIcon
                            sx={{
                              fontSize: "15px",
                              display: "inline-block",
                              cursor: "pointer",
                              marginLeft: "98%",
                              fontSize: "15px",
                              cursor: "pointer",
                            }}
                            onClick={() =>
                              handleClickOpen("addressDetails", addEle)
                            }
                          />

                          <Stack direction="row" spacing={4}>
                            <Box sx={{ width: "190px" }}>
                              <Stack direction="column" spacing={1}>
                                <Typography>
                                  Landmark:{" "}
                                  {addEle.landmark ? addEle.landmark : "-"}
                                </Typography>
                                <Typography>
                                  City: {addEle.city ? addEle.city : "-"}
                                </Typography>
                              </Stack>
                            </Box>
                            <Box sx={{ width: "190px" }}>
                              <Stack direction="column" spacing={1}>
                                <Typography>
                                  Pincode:{" "}
                                  {addEle.zipcode ? addEle.zipcode : "-"}
                                </Typography>
                                <Typography>
                                  State: {addEle ? addEle.state : "-"}
                                </Typography>
                              </Stack>
                            </Box>
                          </Stack>
                        </CardContent>
                      </Card>
                    );
                  })}
            </Box>

            {/* test result components  */}
            <Box
              sx={{
                width: "28.5em",
                margin: "auto",
                color: "#696984",
              }}
            >
              <Typography
                sx={{
                  marginTop: "15px",
                  marginBottom: "15px",
                  fontSize: "1.125em",
                  color: "#696984",
                  fontWeight: "bold",
                }}
              >
                Tests Results
              </Typography>

              <Card
                sx={{
                  backgroundColor: "#FFFFFF",
                  color: "#696984",
                  border: "1px solid #7E7E7E",
                  paddingTop: "5px",
                  width: "auto",
                }}
              >
                {testDet.map((testData, index) => {
                  console.log("666", testData);

                  return (
                    <CardContent
                      color="#696984"
                      sx={{ paddingTop: "0px", paddingBottom: "0px" }}
                    >
                      <Box>
                        <Typography
                          className={styles.capLetter}
                          onClick={(e) =>
                            openTestPdf(e, user.id, testData.TestType)
                          }
                        >
                          {testData.TestType === "achievement"
                            ? "Academics:"
                            : `${testData.TestType}:`}
                          <div className={styles.testBoxGroup}>
                            {testData.data.length == 0
                              ? "No Details Available"
                              : testData.data.map((testSingleData, index) => {
                                  console.log("sing", testSingleData);
                                  return (
                                    <div
                                      className={
                                        testSingleData.submitted == true
                                          ? styles.testBox
                                          : styles.testBoxFail
                                      }
                                    >
                                      {testSingleData.test_name}
                                    </div>
                                  );
                                })}
                          </div>
                        </Typography>
                      </Box>
                    </CardContent>
                  );
                })}
              </Card>
            </Box>

            {/* session components */}
            <Box
              sx={{
                width: "28.5em",
                margin: "auto",
                color: "#696984",
              }}
            >
              <Typography
                sx={{
                  marginTop: "15px",
                  marginBottom: "15px",
                  fontSize: "1.125em",
                  color: "#696984",
                  fontWeight: "bold",
                }}
              >
                Sessions: [{sessions.length}]
              </Typography>

              {sessions.map((sessElement, index) => {
                console.log("sss1234", sessElement);
                return (
                  <Card
                    sx={{
                      backgroundColor: "#FFFFFF",
                      color: "#696984",
                      border: "1px solid #7E7E7E",
                      marginBottom: "10px",
                    }}
                  >
                    <CardContent sx={{ paddingTop: "9px" }}>
                      <Stack direction="row" spacing={3}>
                        <Box sx={{ width: "190px" }}>
                          <Stack direction="column" spacing={1}>
                            <Typography>Session: {index + 1}</Typography>
                            <Typography>
                              Counsellor:{" "}
                              {sessElement ? sessElement.counsellor : "-"}
                            </Typography>

                            <div className={styles.ratings}>
                              <p className={styles.rateName}>Rating:</p>
                              <p className={styles.rateFontSize}>
                                {" "}
                                <Rating
                                  name="read-only"
                                  value={sessElement.review?.rating}
                                  readOnly
                                />
                              </p>
                            </div>
                          </Stack>
                        </Box>

                        <Box sx={{ width: "190px" }}>
                          <Stack direction="column" spacing={1}>
                            <Typography>
                              Date: {sessElement ? sessElement.date : "-"}
                            </Typography>
                            <Typography>
                              Time:{" "}
                              {sessElement
                                ? sessElement.time_slot.commence
                                : "-"}
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>
                      <Box sx={{ marginTop: "5px" }}>
                        <Typography>
                          Feedback:{" "}
                          {sessElement ? sessElement.review?.comment : ""}
                          {/* <TextField
                          fullWidth="true"
                          id="standard-basic"
                          sx={{ borderBottom: "1px solid #696984" }}
                          variant="standard"
                          type=
                        /> */}
                          {/* <TextField
                          id="standard-basic"
                          fullWidth="true"
                          sx={{ borderBottom: "1px solid #696984" }}
                          variant="standard"
                        /> */}
                        </Typography>
                      </Box>

                      {/* <Box
                        sx={{
                          width: "111%",
                          margin: "auto",
                          color: "#696984",
                        }}
                      >
                        <Typography
                          sx={{
                            marginTop: "15px",
                            marginBottom: "15px",
                            fontSize: "1.125em",
                            color: "#696984",
                            fontWeight: "bold",
                          }}
                        >
                          Session Notes{" "}
                          <AddIcon
                            marginLeft="100"
                            className={styles.addMargin}
                            onClick={(e) =>
                              handleAddNotes(
                                sessElement.id,
                                e,
                                sessElement,
                                user
                              )
                            }
                          />
                        </Typography>

                        {sessElement.session_notes
                          ? Object.values(sessElement.session_notes).map(
                              (notes, index) => {
                                console.log("notes3", index);
                                return (
                                  <Card
                                    sx={{
                                      backgroundColor: "#FFFFFF",
                                      color: "#696984",
                                      border: "1px solid #7E7E7E",
                                      width: "90%",
                                      marginTop: "10px",
                                    }}
                                  >
                                    <CardContent>
                                      <Box>
                                        <Typography>
                                          Note {index + 1}:{" "}
                                          <EditIcon
                                            sx={{
                                              display: "inline-block",
                                              cursor: "pointer",
                                              marginLeft: "98%",
                                              fontSize: "15px",
                                              cursor: "pointer",
                                              position: "relative",
                                              top: "-34px",
                                            }}
                                            onClick={() => {
                                              handleSessionNotesEdit(
                                                index,
                                                true,
                                                sessElement
                                              );
                                            }}
                                          />
                                          {editNotesForm.check &&
                                          editNotesForm.id == index ? (
                                            <EditSessionNotes
                                              notes={notes}
                                              id={sessElement.id}
                                              userId={user.id}
                                            />
                                          ) : (
                                            notes
                                          )}
                                        </Typography>
                                      </Box>
                                    </CardContent>
                                  </Card>
                                );
                              }
                            )
                          : null}

                        {addSNote.map((e) =>
                          e.id == sessElement.id ? e.e : null
                        )}
                      </Box> */}
                      {/* january changes */}
                      <CustomSessionNotes
                        id={sessElement?.id}
                        userId={user.id}
                        session_field={
                          sessElement?.session_notes?.session_field
                        }
                      />
                      <CustomStudentProfile
                        id={sessElement?.id}
                        userId={user.id}
                      />
                      <CustomDreamCareer
                        id={sessElement?.id}
                        userId={user.id}
                        dream={sessElement?.dream}
                      />

                      <CustomMindDreamCareer
                        id={sessElement?.id}
                        userId={user.id}
                      />
                      <CustomConcern id={sessElement?.id} userId={user.id} />
                      <CustomJudgement id={sessElement?.id} userId={user.id} />
                      <CustomRecommendation
                        id={sessElement?.id}
                        userId={user.id}
                      />
                    </CardContent>
                  </Card>
                );
              })}

              {/* {sessions.map((sessElement, index) => {
                return (
                  <Card
                    sx={{
                      backgroundColor: "#FFFFFF",
                      color: "#696984",
                      border: "1px solid #7E7E7E",
                      marginBottom: "10px",
                    }}
                  >
                    <CardContent sx={{ paddingTop: "9px" }}>
                      <Stack direction="row" spacing={3}>
                        <Box sx={{ width: "190px" }}>
                          <Stack direction="column" spacing={1}>
                            <Typography>Session: {index + 1} </Typography>
                            <Typography>
                              Counsellor:{" "}
                              {sessElement ? sessElement.counsellor : "-"}
                            </Typography>

                            <div className={styles.ratings}>
                              <p className={styles.rateName}>Rating:</p>
                              <p className={styles.rateFontSize}>
                                {" "}
                                <Rating
                                  name="read-only"
                                  value={sessElement.review?.rating}
                                  readOnly
                                />
                              </p>
                            </div>
                          </Stack>
                        </Box>

                        <Box sx={{ width: "190px" }}>
                          <Stack direction="column" spacing={1}>
                            <Typography>
                              Date: {sessElement.date ? sessElement.date : "-"}
                            </Typography>
                            <Typography>
                              Time:{" "}
                              {sessElement.time_slot
                                ? sessElement.time_slot.commence
                                : "-"}
                            </Typography>
                          </Stack>
                        </Box>
                      </Stack>
                      <Box sx={{ marginTop: "5px" }}>
                        <Typography>
                          Feedback:{" "}
                          {sessElement ? sessElement.review?.comment : ""}
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          width: "111%",
                          margin: "auto",
                          color: "#696984",
                        }}
                      >
                        <Typography
                          sx={{
                            marginTop: "15px",
                            marginBottom: "15px",
                            fontSize: "1.125em",
                            color: "#696984",
                            fontWeight: "bold",
                          }}
                        >
                          Session Notes{" "}
                          <AddIcon
                            marginLeft="100"
                            className={styles.addMargin}
                          />
                        </Typography>
                      </Box>
                    </CardContent>
                  </Card>
                );
              })} */}
            </Box>
          </Box>
        </Box>
      </Drawer>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <EditFieldsForm
          handleClose={handleClose}
          editSection={sectionType}
          editFormDetails={editObject}
          objForForm={objForForm}
        />
      </BootstrapDialog>
    </div>
  );
};

export default Drawer1;
