import React, { useState } from "react";
import {
  AppBar,
  Badge,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Menu,
  Stack,
  Toolbar,
  Typography,
  Item,
  Avatar,
} from "@mui/material";
import { useContext, useRef } from "react";
import styles from "./sesCard.module.css";
import { token_api } from "../../Utils/Network";
import CustomSnackBar from "../CustomSnackBar/CustomSnackBar";
import { convertdateformat } from "../../Utils/Utils";
import Drawer1 from "../Drawer1/Drawer1";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";
function SessionReqCard(props) {
  const { user, slot, reloadData, date,counselor_name,total_sessions,userData,studentId } = props;
  const { gradeList, instituteList } = useContext(UserCredsContext);
  const [isDrawerOpen,setIsDrawerOpen]   = useState(false);
  const [clickme, setClickme] =  useState(false);
  const [data, setData] =  useState(userData)
  const [filterdata, seFilterData ] =  useState([])


  let lastarray = user.sessions.length - 1;
  let mydate = date;
  let newdate = convertdateformat(mydate);

  const handleAccept = (_action) => {
    token_api
      .get(
        `/counseling/v2/all_slots/allocate_slot/?slot=${slot.id}&status=${_action}`
      )
      .then((response) => {
        reloadData("success", _action);
      })
      .catch((error) => {
        reloadData("error", _action);
      });
  };

  const handleClick = (id) => {
   
    console.log(id);

 
     setClickme(true);
     setIsDrawerOpen(true);
   const abc =   data.find(d => {  
     return d.id === id;
   })
   console.log(abc, "my")

    seFilterData([abc]) 
}


console.log("avatar" , user);
  return (
     
    <div>
      <Box sx={{ width: "400px", marginBottom :"20px" }}>
        <Card
          variant="outline"
          className={styles.cardsty}
          sx={{
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            boxShadow: "1px 1px 10px rgb(228, 228, 228)",
            border: "1px solid #00000033",
       
            paddingLeft: "30px",
            paddingRight: "30px",
            borderRadius: "10px"  

          }}
        >
          <CardContent sx={{ color: "#121111", p: "10px" }}>

            <Box>
              <Stack direction="row" spacing={4} alignItems="center">
                <Avatar
                  alt=""
                  src={user.avatar}
                  className={styles.avatarstyle}
                  sx={{
                    width: 60,
                    height: 60,
                  }}
                />

                <Typography
                  variant="h5"
                  sx={{
                    color: "#121111",
                    fontFamily: "Poppins",
                    fontWeight: "bold",
                    fontSize: "1.25rem",
                    cursor: "pointer"
                  }}
                  onClick={() => handleClick(studentId)}
                >  
                
                  {user.first_name + " " + user.last_name}
                </Typography>
              </Stack>
            </Box>
            <Box className={styles.institute}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Box >
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "0.875rem",
                      color: "#696984"
                    }}
                  >
                    Institute
                  </Typography>
                  <Typography
                    sx={{ fontFamily: "Poppins", 
                    fontSize: "1rem",
                    fontWeight: "bold",
                    width : "11rem",
                    
                  }}
                  >
                   
                   {/* {user.institute_name == "" ? "-" : user.institute_name}  */}
                   {
                          instituteList[
                            instituteList.findIndex(
                              (item) => item.id == user.profile?.institute
                            )
                          ]?.name
                        }

                  </Typography>
                </Box>
                <Box  sx= {{ width : "7.6rem"  }} >
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "Poppins",
                      fontWeight: "400",
                      fontSize: "0.875rem",
                      color: "#696984"
                    }}
                  >
                    Class
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: "Poppins", 
                      fontSize: "1rem",
                      fontWeight: "600",
                    
                    }}
                  >
                    {user.profile?.grade?.title}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={styles.location}>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "0.875rem",
                  color: "#696984",
                 
                }}
              >
                Location
              </Typography>
              <Typography sx={{ 
                    fontFamily: "Poppins", 
                    fontSize: "1rem",
                    fontWeight: "600",
                   
                    width : "18rem"

               }}>

             
                {user.city === null ? "-" : user.city} 
              </Typography>
            </Box>

            <Box sx={{display:"flex", justifyContent: "space-between" }}   >
            <Box>
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "0.875rem",
                  color: "#696984"
                }}
              >
                Date
              </Typography>

              <Typography
                  sx={{
                  fontFamily: "Poppins", 
                  fontSize: "1rem",
                  fontWeight: "600"
                 }}
              >
                    {newdate}
                </Typography>  

            </Box>

             <Box >
             <Typography
                variant="h6"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "0.875rem",
                  color: "#696984"
                }}
              >
              Commence Time 
              </Typography>

              <Typography
                  sx={{
                  fontFamily: "Poppins", 
                  fontSize: "1rem",
                  fontWeight: "600"
                 }}
              >
                {slot.time_slot.commence}
                </Typography>  
              
              
              
              </Box>    
            </Box>   

            {/* new content added */}
            <Box sx={{display:"flex", marginTop: "10px", justifyContent: "space-between " }}   >
            <Box >
              <Typography
                variant="h6"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "0.875rem",
                  color: "#696984"
                }}
              >
                Last Counselling By
              </Typography>

              <Typography
                  sx={{
                  fontFamily: "Poppins", 
                  fontSize: "1rem",
                  fontWeight: "600"
                 }}
              >
             {user.sessions[lastarray].counsellor }

                </Typography>  
             
            </Box>

             <Box sx={{  width :"7.8rem"  }}>
             <Typography
                variant="h6"
                sx={{
                  fontFamily: "Poppins",
                  fontWeight: "400",
                  fontSize: "0.875rem",
                  color: "#696984"
                }}
              >
               Total Session
              </Typography>

              <Typography
                  sx={{
                  fontFamily: "Poppins", 
                  fontSize: "1rem",
                  fontWeight: "600"
                 }}
              >
                  {total_sessions}
                </Typography>  
            
              
              
              </Box>    
            </Box>   



          </CardContent>
          <CardActions sx={{display:"flex", justifyContent:"space-between"}}>
            <Button
              variant="contained"
              className={styles.reject}
              sx={{
                background: "#E92130",
                fontFamily: "Poppins",
                color: "white",
                fontSize: "1.05rem",
                width: "8.5rem",
                height: "2.25rem",
                '&:hover': {
                  background: "#E92130",             
                  }
                
              }}
             
              onClick={() => handleAccept(false)}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              sx={{
                background: "#3DB047",
                width: "8.5rem",
                height: "2.25rem",
                fontFamily: "Poppins",
                fontSize: "1.05rem",
                '&:hover': {
                  background: "#3DB047",    
                               
                  }
              }}
              onClick={() => handleAccept(true)}
       
            >
              Accept
            </Button>
          </CardActions>
        </Card>
      </Box>

    { clickme && isDrawerOpen &&  <Drawer1 isDrawerOpen={isDrawerOpen} onClose={() => setIsDrawerOpen(false)} filterdata={filterdata}  setIsDrawerOpen={setIsDrawerOpen}   />}
    
    </div>
  
  );
}

export default SessionReqCard;
