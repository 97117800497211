import React from 'react';

function LandingPage(){
    return(
        <div>
           landing page
        </div>
    )
}

export default LandingPage;