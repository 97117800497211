import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import BoxShadowC from "../BoxShadowC/BoxShadowC";
import styles from "./SessionComp.module.css";
import { open_api, token_api } from "../../Utils/Network";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import { tConvert } from "../../Utils/Utils";
import Student from "../../Panel/Students/Students";
import Drawer1 from "../Drawer1/Drawer1";
import { session } from "../Fakedata/Fakedata";
import EditIcon from "@mui/icons-material/Edit";
import { gender, editSection } from "../../Utils/Constants";
import { useContext, useRef } from "react";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";
import { DataContext } from "../../ContextApis/DataContext/DataContext";
import {
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputBase,
  makeStyles,
  MenuItem,
  Paper,
  Rating,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { AbcOutlined, ContactSupport } from "@mui/icons-material";

const SessionComp = (props) => {
  const {
    firstname,
    lastname,
    sessionsttime,
    mymonth,
    mydate,
    myyear,
    slotId,
    newinfo,
    singleUserId,
    checkDrawerClick,
  } = props;

  let newTime = tConvert(sessionsttime);

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [clickme, setClickme] = useState(false);
  const [data] = useState([newinfo]);
  const [filterdata, seFilterData] = useState([]);

  const gradeList = useContext(DataContext);
  const primary = gradeList.theme.palette.primary.main;
  const secondary = gradeList.theme.palette.secondary.main;

  const handleStart = () => {
    token_api
      .get(`/counseling/session/${slotId}/join/`)
      .then((response) => {
        console.log(response, "responseresponse");
        window.open(response.data.data.url, "_blank").focus();
      })
      .catch((error) => {});
  };

  const handleClick = (id) => {
    if (checkDrawerClick) {
      setClickme(false);
      setIsDrawerOpen(false);
    }

    setClickme(true);
    setIsDrawerOpen(true);
    const abc = data.find((d) => {
      return d.id === id;
    });
    seFilterData([abc]);
  };

  return (
    <>
      <div style={{ width: "55.313rem", marginTop: "20px" }}>
        <BoxShadowC>
          <div
            style={{
              width: "55.313rem",
              borderRadius: "10px",
              padding: "5px 10px",
            }}
          >
            <Typography
              sx={{
                width: "3rem",
                fontFamily: "poppins",
                fontWeight: "600",
                fontSize: "0.75rem",
                fontStyle: "normal",
                color: "#2F327D",
                padding: "0.438  1.438rem",
              }}
            >
              Student
            </Typography>

            <Box
              sx={{
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    width: "17.688rem",
                    fontFamily: "poppins",
                    fontSize: "1.125rem",
                    fontWeight: "500",
                    color: "#2F327D",
                    cursor: "pointer",
                  }}
                  onClick={() =>
                    checkDrawerClick ? null : handleClick(singleUserId)
                  }
                >
                  {`${firstname} ${lastname}`}
                </Typography>

                <Typography
                  sx={{
                    width: "6.625rem",
                    fontFamily: "poppins",
                    fontSize: "1.125rem",
                    fontWeight: "400",
                    fontStyle: "normal",
                    color: "#2F327D",
                  }}
                >
                  {`${newTime}`}
                </Typography>
                <Button
                  variant="contained"
                  sx={{
                    width: "9.563rem",
                    bgcolor: { primary },
                    textTransform: "none",
                    fontSize: "1.125rem",
                    fontWeight: "400",
                    disableRipple: "true",
                    marginLeft: "3rem",
                    "&:hover": {
                      background: { primary },
                    },
                  }}
                  onClick={handleStart}
                >
                  Start session
                </Button>
              </Box>

              <Box>
                <Typography
                  sx={{
                    width: "12.188rem",
                    fontFamily: "poppins",
                    fontSize: "0.75rem",
                    fontWeight: "400",
                    fontStyle: "normal",
                    color: "#2F327D",
                  }}
                >
                  Date: {`${mydate} ${mymonth} ${myyear}`}
                </Typography>
              </Box>
            </Box>
          </div>
        </BoxShadowC>
      </div>
      {clickme && isDrawerOpen && (
        <Drawer1
          isDrawerOpen={isDrawerOpen}
          onClose={() => setIsDrawerOpen(false)}
          filterdata={filterdata}
          setIsDrawerOpen={setIsDrawerOpen}
        />
      )}
    </>
  );
};

export default SessionComp;
