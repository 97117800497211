import React, { useEffect } from "react";
import { Route, Routes, useNavigate, useLocation } from "react-router-dom";
import Home from "../Pages/Home/Home";
import { useContext } from "react";
import { ThemeProvider } from "@mui/material/styles";
import { DataContext } from "../ContextApis/DataContext/DataContext";
import Login from "../Authentication/Login/Login";
import { Button } from "@mui/material";
import LandingPage from '../Panel/LandingPage/LandingPage';
import Dashboard1 from "../Pages/Dashboard1/Dashboard1";

function AppRouter() {
  const { theme } = useContext(DataContext);
  let navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname === "/") {
      navigate("/login");
    }
  }, []);

  const handleRoute = (_url) => {
    navigate(`${_url}`);
  };
console.log(theme,'theme is')
  return (
    <div>
      <ThemeProvider theme={theme}>
        {/* <Button onClick={() => handleRoute("/home")}>Redirect to Home</Button>
        <Button onClick={() => handleRoute("/login")}>Redirect to Login</Button> */}
        <Routes>
          <Route strict exact path="/home" element={<Home />} />
          <Route path="/dashboard/*" element={<Dashboard1 />} />
          <Route strict exact path="/login" element={<Login />} />
          <Route strict exact path="/landingpage" element={<LandingPage />} />

        </Routes>
      </ThemeProvider>
    </div>
  );
}

export default AppRouter;
