import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import styles from "./recom.module.css";
import { useEffect } from "react";
import { token_api } from "../../Utils/Network";
import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

mic.continuous = true;
mic.interimResults = true;
mic.lang = "en-US";

const CustomRecommendation = ({ userId, id, ...props }) => {
  const [card, setCard] = useState(false);
  const [recomm, setRecomm] = useState("");
  const [isListening, setIsListening] = useState(false);
  const [note, setNote] = useState(null);
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    handleListen();
  }, [isListening]);

  const handleListen = () => {
    if (isListening) {
      mic.start();
      mic.onend = () => {
        // console.log("continue..");
        mic.start();
      };
    } else {
      mic.stop();
      mic.onend = () => {
        // console.log("Stopped Mic on Click");
      };
    }
    mic.onstart = () => {
      // console.log("Mics on");
    };

    mic.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");
      // console.log(transcript);
      setNote(transcript);
      setRecomm(transcript);
      mic.onerror = (event) => {
        // console.log(event.error);
      };
    };
  };

  const handleStudentProfileSubmit = (e) => {
    e.preventDefault();
    setCard(true);
    // hit THE API
    let data = {};
    data = {
      user_id: userId,
      extra_information: {
        session: id,
        recommendation: recomm,
      },
    };

    token_api
      .post(
        `/counseling/add/student/update_student_profile/?user=${userId}`,
        data
      )
      .then((response) => {
        console.log("CheckingResponseafterpost", response.data.data);
        // setStudentDream(response?.data?.data?.dream)
      })
      .catch((err) => {
        console.log(err);
      });
    setEdit(true);
    console.log("re", data);
  };

  const toggleEdit = () => {
    setCard(false);
    setEdit(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    // DISPATCH

    setRecomm(value);
  };

  // console.log("studentProfilew", recomm);
  return (
    <>
      <div className={styles.cardContainer}>
        <div className={styles.editStyle}>
          <span className={styles.font20}>Recommendation</span>
          {isListening == false ? (
            <MicOffIcon
              sx={{ color: "#f71302" }}
              onClick={() => setIsListening((prevState) => !prevState)}
            />
          ) : (
            <MicIcon
              sx={{ color: "#02f733" }}
              onClick={() => setIsListening((prevState) => !prevState)}
            />
          )}
          {/* <Button
            size="small"
            onClick={() => setIsListening((prevState) => !prevState)}
          >
            {" "}
            Start/Stop Mic
          </Button> */}

          <div style={{ display: "flex" }}>
            {/* <span onClick={SpeechRecognition.startListening}>
              <MicIcon />
            </span>

            <span onClick={SpeechRecognition.stopListening}>
              <MicOffIcon />
            </span> */}
            {edit && (
              <span style={{ cursor: "Pointer" }} onClick={toggleEdit}>
                Edit
              </span>
            )}
          </div>
        </div>
        {card == false ? (
          <>
            <div>
              <form onSubmit={handleStudentProfileSubmit}>
                <TextField
                  fullWidth
                  //   id="outlined-required"
                  //   label="Required"

                  onChange={handleChange}
                  name="recommendation"
                  value={recomm}
                  multiline
                  required
                  rows={4}
                />
                <Button
                  style={{ position: "relative", left: "82%" , marginTop:'10px' }}
                  size="small"
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
              </form>
            </div>
          </>
        ) : (
          <>
            <div className={styles.showCard}>{recomm && recomm}</div>
          </>
        )}

        {/* show card */}
      </div>
    </>
  );
};

export default CustomRecommendation;
