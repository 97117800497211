import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import styles from "./profile.module.css";

import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";
import ProfileDict from "../ProfileDict/ProfileDict";
import { token_api } from "../../Utils/Network";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

mic.continuous = true;
mic.interimResults = true;
mic.lang = "en-US";

const CustomStudentProfile = ({ userId, id, stuProfile, ...props }) => {
  const [isListening, setIsListening] = useState(false);
  const [note, setNote] = useState(null);
  const [card, setCard] = useState(false);
  const [studentProfile, setStudentprofile] = useState("");
  const [edit, setEdit] = useState(false);
  const [fieldDict, setFieldDict] = useState(null);
  // const { transcript, resetTranscript } = useSpeechRecognition();
  // useEffect(() => {
  //   setStudentprofile(transcript);
  // }, [transcript]);

  // if (!SpeechRecognition.browserSupportsSpeechRecognition()) {
  //   return null;
  // }

  useEffect(() => {
    handleListen();
  }, [isListening]);

  useEffect(() => {
    setStudentprofile(stuProfile);
    // if(stuProfile){
    //   setEdit(true)
    // }
  }, []);

  const handleListen = () => {
    if (isListening) {
      mic.start();
      mic.onend = () => {
        console.log("continue..");
        mic.start();
      };
    } else {
      mic.stop();
      mic.onend = () => {
        console.log("Stopped Mic on Click");
      };
    }
    mic.onstart = () => {
      console.log("Mics on");
    };

    mic.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");
      console.log(transcript);
      setNote(transcript);
      setStudentprofile(transcript);
      mic.onerror = (event) => {
        console.log(event.error);
      };
    };
  };

  const handleSpeechData = (speechToText) => {
    console.log("SpeechTotextinMain", speechToText);
    setStudentprofile(speechToText);
  };

  const handleStudentProfileSubmit = (e) => {
    e.preventDefault();
    setCard(true);
    // hit THE API

    let data = {};

    data = {
      user_id: userId,
      extra_information: {
        session: id,
        student_profile: studentProfile,
      },
    };

    token_api
      .post(
        `/counseling/add/student/update_student_profile/?user=${userId}`,
        data
      )
      .then((response) => {
        console.log("CheckingResponseafterpost", response.data.data);
        // setStudentDream(response?.data?.data?.dream)
      })
      .catch((err) => {
        console.log(err);
      });
    setEdit(true);
  };

  const toggleEdit = () => {
    setCard(false);
    setEdit(false);
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    // DISPATCH

    setStudentprofile(value);
  };

  console.log("studentProfilew", studentProfile);
  return (
    <>
      <div className={styles.cardContainer}>
        <div className={styles.editStyle}>
          <span className={styles.font20}>Student Profile</span>

          {isListening == false ? (
            <MicOffIcon
              sx={{ color: "#f71302" }}
              onClick={() => setIsListening((prevState) => !prevState)}
            />
          ) : (
            <MicIcon
              sx={{ color: "#02f733" }}
              onClick={() => setIsListening((prevState) => !prevState)}
            />
          )}

          {/* <Button
            onClick={() => setIsListening((prevState) => !prevState)}
            size="small"
          >
            {" "}
            Start/Stop mic
          </Button> */}
          <div style={{ display: "flex" }}>
            {/* <span onClick={SpeechRecognition.startListening}>
              <MicIcon />
            </span>

            <span onClick={SpeechRecognition.stopListening}>
              <MicOffIcon />
            </span> */}

            {/* {fieldDict === "review" ? (
              <ProfileDict speechData={handleSpeechData}  />
            ) : (
              <center>
                <MicIcon onClick={() => setFieldDict("review")} />
              </center>
            )} */}
            {/* <ProfileDict/> */}

            {edit && (
              <span style={{ cursor: "Pointer" }} onClick={toggleEdit}>
                Edit
              </span>
            )}
          </div>

          {/* <Button >Speech Stop</Button> */}
          {/* <Button onClick={resetTranscript}>Reset </Button> */}
        </div>
        {card == false ? (
          <>
            <div>
              <form onSubmit={handleStudentProfileSubmit}>
                <TextField
                  fullWidth
                  //   id="outlined-required"
                  //   label="Required"

                  onChange={handleChange}
                  name="student_profile"
                  value={studentProfile}
                  multiline
                  required
                  rows={4}
                />
                <Button
                  style={{
                    position: "relative",
                    left: "82%",
                    marginTop: "10px",
                  }}
                  size="small"
                  variant="contained"
                  type="submit"
                >
                  Save
                </Button>
              </form>
            </div>
          </>
        ) : (
          <>
            <div className={styles.showCard}>
              {studentProfile && studentProfile}
            </div>
          </>
        )}

        {/* show card */}
      </div>
    </>
  );
};

export default CustomStudentProfile;
