import React, { Component, createContext } from "react";
import { createTheme } from "@mui/material/styles";

export const DataContext = createContext();

class DataContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: createTheme({
        palette: {
          primary: { 500: "#032c6b" },
          secondary: { A400: "#12878b" },
          white: { 500: "rgba(255,255,255)" },
          mode: "light",
        },
      }),
    };
  }

  componentDidMount(){
    
  }

  render() {
    return (
      <DataContext.Provider
        value={{
          theme: this.state.theme,
        }}
      >
        {this.props.children}
      </DataContext.Provider>
    );
  }
}

export default DataContextProvider;
