import React, { useState, useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import "./CustomDatePicker.css";


function CustomDatePicker({
  label,
  name,
  type,
  isClearable = false,
  handleDate,
  selected,
  placeholderText = "Select Date dd/mm/yy",
  dateFormat = "dd/MM/yyyy",

  ...props
}) {
  return (
    <React.Fragment>
      <div style={{ zIndex: "999",  }}>
        <DatePicker
          selected={selected}
          onChange={handleDate}
          isClearable={isClearable}
          placeholderText={placeholderText}
          dateFormat={dateFormat}
          // showYearDropdown={true}
          // showMonthDropdown={true}
          scrollableMonthYearDropdown={true}
        

          //   minDate={
          //     name === "register_date_before"
          //       ? setDate
          //       : name === "schedule_date" && new Date()
          //   }
          //   maxDate={name !== "schedule_date" && new Date()}
        />
      </div>
    </React.Fragment>
  );
}

export default CustomDatePicker;
