import moment from "moment"
export function findObject(myArray, string, keyName) {
  const newObject = myArray.find((item) => item[keyName] === string);
  return newObject;
}

export function findPayObject(myArray , string , keyName){
  const newPayObject = myArray.find((item) => item[keyName] === string);
  return newPayObject;
}
export function yearMonthDayFormat(dateObj) {

  const dateCorrect =   moment(dateObj).format('Y-MM-D')
     
  const newday  =  dateCorrect.split("-");
  var month = newday[1]; //months from 1-12
  var day = newday[2];
  var year = newday[0];

 console.log(day, "new day")


  return { month, day, year };
}

export function convertdateformat(mydate) {
  var date = mydate;
  date = date.split("-").reverse().join("-");
  return date;
}

export function gettingmonth(abc) {
  const d = new Date(abc);
  const month = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  let mymonth = month[d.getMonth()];
  let mydate = d.getDate();
  let myyear = d.getFullYear();

  return [mymonth, mydate, myyear];
}


export function tConvert(time) {
  // Check correct time format and split into components
  time = time.toString ().match (/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (time.length > 1) { // If time format correct
    time = time.slice (1);  // Remove full string match value
    time[5] = +time[0] < 12 ? ' AM' : ' PM'; // Set AM/PM
    time[0] = +time[0] % 12 || 12; // Adjust hours
  }
  return time.join (''); // return adjusted time or original string
}


function helperDate(date_){
  const dateString = date_.toISOString().split("T");
  let a =  dateString[0].split("-");
  let year  =   a[0];
  let month  =  a[1];
  let day =     a[2];
     return {year, month , day};

}