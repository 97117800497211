import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import SessionComp from "../SessionComp/SessionComp";
import { token_api } from "../../Utils/Network";
import { Skeleton, TextField } from "@mui/material";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";
import MenuItem from "@mui/material/MenuItem";
import InputFieled from "../CustomFields/InputField/InputField";
import { gettingmonth } from "../../Utils/Utils";
import CustomDatePicker from "../CustomDatePicker/CustomDatePicker";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { yearMonthDayFormat,tConvert } from "../../Utils/Utils";
import {Fakedata, session} from "../../Components/Fakedata/Fakedata"
import styles from "./SingleSession.module.css";
import { border } from "@mui/system";
import { DataContext } from "../../ContextApis/DataContext/DataContext";


const SingleSession = () => {

  const  gradeList  = useContext(DataContext);

  const primary  = gradeList.theme.palette.primary.main 
  const secondary = gradeList.theme.palette.secondary.main; 

  const [newdata, setnewData] = useState([]);
  const [loading, setLoading] = useState(true);
 
  const [filterData, setFilterData] = useState({
    searchParam: "",
    grade: "",
    date: "",
  });

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchSessions(filterData.searchParam, filterData.date);
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [filterData.searchParam]);

  const fetchSessions = (_search, _date) => {
    var search = _search === undefined ? "" : _search;
    setLoading(true);
    // var url_param = `&grade=${_grade}`;
    // if (_grade === null || _grade === undefined || _grade === -1) {
    //   var url_param = ``;
    // }
    var url_param = "";
    if (_search) {
      url_param = `?search=${search}`;
    }
    if (_date) {
      const { month, day, year } = yearMonthDayFormat(_date);

      console.log(day, "single")

      url_param =
        url_param === ""
          ? `?month=${month}&year=${year}&day=${day}`
          : `${url_param}&month=${month}&year=${year}&day=${day}`;
    }
 
    token_api
      .get(`/counseling/v2/all_slots/allocated_instructor_slot/${url_param}`)
      .then((response) => {
        setnewData(response.data.data);

        setLoading(false);
      });
  };

  useEffect(() => {
    fetchSessions("", null);
  }, []);

  const handleChange = (e) => {
    console.log(e.target.value);
    const { name, value } = e.target;
    setFilterData({ ...filterData, [name]: value });
    if (name === "grade") {
      fetchSessions(filterData.searchParam, value);
    }
  };

  const handleDate = (_date) => {
    setFilterData({ ...filterData, date: _date });
    fetchSessions(filterData.searchParam, _date);
  };




  return (
    <div>
      <div style={{ display: "flex" }}>
        <InputFieled
          label="Search... "
          style={{ width: "13.438rem", marginRight: "20px" }}
          value={filterData.searchParam}
          name="searchParam"
          handleChange={handleChange}
        />

        <div  style={{ marginTop: "5px",}}>
          <CustomDatePicker
            selected={filterData.date}
            handleDate={(date) => handleDate(date)}
             className ={styles.hello}



          />
        </div>
      </div>

      {!loading && newdata.length === 0 && (
        <p style={{ marginTop: "30px", fontWeight: "bold" }}>
          No data to display
        </p>
      )}

 
    

  {/* use newdata  instead of Fakedata for iteration  */}

      {newdata.map((info) => {
        const abc = info.date;
        const mynewmonth = gettingmonth(abc);
        const mymonth = mynewmonth[0];
        const mydate = mynewmonth[1];
        const myyear = mynewmonth[2];
        const firstname = info.user.first_name;
        const lastname = info.user.last_name;
        const sessionsttime = info.slot.time_slot.commence;
        const newinfo =  info;
        const singleUserId = info.id;
        //  console.log(newinfo)

        return (
          <SessionComp
            slotId={info.slot.id}
            firstname={firstname}
            lastname={lastname}
            sessionsttime={sessionsttime}
            mymonth={mymonth}
            mydate={mydate}
            myyear={myyear}
            newinfo = {newinfo}
            singleUserId = {singleUserId} 
          />
        );
      })}

      {loading && (
        <div>
          <div>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
          <div style={{ marginTop: "20px" }}>
            <Skeleton />
            <Skeleton />
            <Skeleton />
          </div>
        </div>
      )}
    </div>
  );
};

export default SingleSession;
