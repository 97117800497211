import React, { useState } from "react";
import InputField from "../../Components/CustomFields/InputField/InputField";
import Dashboard from "../Dashboard/Dashboard";

function Home() {
  const [test, setTest] = useState({ testField: undefined });
  const handleChange = (_name, _value) => {
    console.log(_name, _value);
    setTest({ [_name]: _value });
  };
  console.log(typeof test.testField, "type of");
  return (
    <div>
      {/* <InputField
        onChange={handleChange}
        name="testField"
        value={test.testField}
        disabled={false}
        type="number"
      /> */}
      <Dashboard />
    </div>
  );
}

export default Home;
