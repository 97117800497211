import React, { useEffect, useState } from "react";
import styles from "./feedback.module.css";
import { Button, Rating, TextField } from "@mui/material";
import { token_api } from "../../Utils/Network";

import MicIcon from "@mui/icons-material/Mic";
import MicOffIcon from "@mui/icons-material/MicOff";

const SpeechRecognition =
  window.SpeechRecognition || window.webkitSpeechRecognition;
const mic = new SpeechRecognition();

mic.continuous = true;
mic.interimResults = true;
mic.lang = "en-US";

function CustomReviewFeedBack({ id, userId, ratingValue, feedBack }) {
  const [cFeedBack, setCFeedback] = useState("");
  const [cRating, setCRating] = useState(0);
  const [isListening, setIsListening] = useState(false);
  const [note, setNote] = useState(null);

  useEffect(() => {
    setCFeedback(feedBack);
    setCRating(ratingValue);
  }, [ratingValue, feedBack]);

  useEffect(() => {
    handleListen();
  }, [isListening]);

  const handleListen = () => {
    if (isListening) {
      mic.start();
      mic.onend = () => {
        console.log("continue..");
        mic.start();
      };
    } else {
      mic.stop();
      mic.onend = () => {
        console.log("Stopped Mic on Click");
      };
    }
    mic.onstart = () => {
      console.log("Mics on");
    };

    mic.onresult = (event) => {
      const transcript = Array.from(event.results)
        .map((result) => result[0])
        .map((result) => result.transcript)
        .join("");
      console.log(transcript);
      setNote(transcript);
      setCFeedback(transcript);
      mic.onerror = (event) => {
        console.log(event.error);
      };
    };
  };
  const handleStudentProfileSubmit = (e) => {
    e.preventDefault();
    // setCard(true);
    // hit THE API

    let data = {};

    // data = {
    //   user_id: userId,
    //   extra_information: {
    //     session: id,
    //     student_profile: cFeedBack,
    //   },
    // };
    data = {
      user_id: userId,
      slot: id,
      rating: cRating,
      comment: cFeedBack,
    };
    console.log(data, "u908yiujknkjgjh");

    token_api
      .post(`/counseling/review_session/`, data)
      .then((response) => {
        console.log("CheckingResponseafterpost", response.data.data);
        // setStudentDream(response?.data?.data?.dream)
      })
      .catch((err) => {
        console.log(err);
      });
    // setEdit(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    // DISPATCH

    setCFeedback(value);
  };
  const handleRatingChange = (event, newValue) => {
    setCRating(newValue); // Update the rating value
  };
  return (
    <div className={styles.mainContainer}>
      <div className={styles.ratings}>
        <p className={styles.rateName}>Rating:</p>
        <p className={styles.rateFontSize}>
          {" "}
          <Rating
            name="read-only"
            value={cRating}
            onChange={handleRatingChange}
            // readOnly
          />
        </p>
      </div>
      <div className={styles.editStyle}>
      <span className={styles.font20}>Feedback</span>

      {isListening == false ? (
        <MicOffIcon
          sx={{ color: "#f71302" }}
          onClick={() => setIsListening((prevState) => !prevState)}
        />
      ) : (
        <MicIcon
          sx={{ color: "#02f733" }}
          onClick={() => setIsListening((prevState) => !prevState)}
        />
      )}
      </div>
      <div>
        <form onSubmit={handleStudentProfileSubmit}>
          <TextField
            fullWidth
            onChange={handleChange}
            name="student_profile"
            value={cFeedBack}
            multiline
            required
            rows={4}
          />
          <Button
            style={{
              position: "relative",
              left: "82%",
              marginTop: "10px",
            }}
            size="small"
            variant="contained"
            type="submit"
          >
            Save
          </Button>
        </form>
      </div>
    </div>
  );
}

export default CustomReviewFeedBack;
