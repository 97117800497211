// import { useEffect, useState } from "react";
// import Card from "@mui/material/Card";
// import CardContent from "@mui/material/CardContent";
// import CardMedia from "@mui/material/CardMedia";
// import Typography from "@mui/material/Typography";
// import { Button, CardActionArea, CardActions, Box, Stack } from "@mui/material";
// import BoxShadowC from "../../Components/BoxShadowC/BoxShadowC";
// import styles from "./dashboard.module.css";
// import axios from "axios";
// import { token_api } from "../../Utils/Network";
// import { FormatColorResetOutlined, Info } from "@material-ui/icons";
// import { Skeleton } from "@mui/material";
// import SessionComp from "../../Components/SessionComp/SessionComp";
// import { gettingmonth } from "../../Utils/Utils";
// import { Fakedata } from "../../Components/Fakedata/Fakedata";

// export default function BoxSx() {
//   const [newdata, setnewData] = useState([]);
//   const [loading, setLoading] = useState(true);
//   const [checkDrawerClick , setCheckclick] =  useState(true);

//   const fetchSessions = async () => {
//     setLoading(true);
//     try {
//       const response = await token_api.get(
//         "/counseling/v2/all_slots/allocated_instructor_slot"
//       );
//       //  (response);
//       setnewData(response.data.data);
//       setLoading(false);
//       console.log(newdata);
//     } catch (error) {
//       console.log(error);
//     }
//   };

//   useEffect(() => {
//     fetchSessions();
//   }, []);

//   const abc = false;
//   return (
//     <>
//       <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
//         <BoxShadowC>
//           <Box
//             sx={{
//               width: "26rem",
//               borderRadius: 2,
//             }}
//           >
//             <Typography
//               variant="h5"
//               gutterBottom
//               sx={{
//                 fontFamily: "Poppins",
//                 fontWeight: "600",
//                 fontSize: "1.125rem",
//                 lineHeight: "27px",
//                 color: "#2F327D",
//                 textAlign: "center",
//                 mt: "1.063rem",
//               }}
//             >
//               Today's Counselling Sessions
//             </Typography>

//             <Typography
//               className={styles.mynumbers}
//               sx={{
//                 fontFamily: "Poppins",
//                 fontSize: "2.5rem",
//                 fontWeight: 600,
//                 mt: "0.65rem",
//                 color: "#2F327D",
//                 textAlign: "center",
//               }}
//             >
//               -/-
//             </Typography>

//             <Box className={styles.bottomcon} direction="row">
//               <Typography
//                 className={styles.mynumbers}
//                 sx={{
//                   textAlign: "center",
//                   color: "#2F327D",
//                   width: "2rem",

//                   fontSize: "0.75rem",
//                   fontWeight: 550,
//                   mt: "0.188rem",
//                 }}
//               >
//                 Done
//               </Typography>

//               <Typography
//                 className={styles.mynumbers}
//                 sx={{
//                   textAlign: "center",
//                   color: "#2F327D",
//                   width: "2rem",
//                   fontSize: "0.75rem",
//                   fontWeight: 550,
//                   mt: "0.188rem",
//                 }}
//               >
//                 All
//               </Typography>
//             </Box>
//           </Box>
//         </BoxShadowC>

//         {/* second components */}
//         <BoxShadowC>
//           <Box
//             sx={{
//               width: "26rem",
//               borderRadius: 2,
//             }}
//           >
//             <Typography
//               variant="h5"
//               gutterBottom
//               sx={{
//                 fontFamily: "Poppins",
//                 fontWeight: "600",
//                 fontSize: "1.125rem",
//                 lineHeight: "27px",
//                 color: "#2F327D",
//                 textAlign: "center",
//                 mt: "1.063rem",
//               }}
//             >
//               This Month Counselling Sessions
//             </Typography>

//             <Typography
//               className={styles.mynumbers}
//               sx={{
//                 fontFamily: "Poppins",
//                 fontSize: "2.5rem",
//                 fontWeight: 600,
//                 mt: "0.65rem",
//                 color: "#2F327D",
//                 textAlign: "center",
//               }}
//             >
//               -/-
//             </Typography>

//             <Box className={styles.bottomcon} direction="row">
//               <Typography
//                 className={styles.mynumbers}
//                 sx={{
//                   textAlign: "center",
//                   color: "#2F327D",
//                   width: "2rem",
//                   fontSize: "0.75rem",
//                   fontWeight: 550,
//                   mt: "0.188rem",
//                 }}
//               >
//                 Done
//               </Typography>

//               <Typography
//                 className={styles.mynumbers}
//                 sx={{
//                   textAlign: "center",
//                   color: "#2F327D",
//                   width: "2rem",
//                   fontSize: "0.75rem",
//                   fontWeight: 550,
//                   mt: "0.188rem",
//                 }}
//               >
//                 All
//               </Typography>
//             </Box>
//           </Box>
//         </BoxShadowC>
//       </Box>
//       {/* top component end here */}
//       <Box>
//         <Typography
//           className={styles.mynumbers}
//           sx={{
//             maxWidth: "11.563rem",
//             fontFamily: "poppins",
//             fontSize: "1.125rem",
//             mt: "2.5rem",
//             mb: "2rem",
//             fontWeight: "600",
//             color: "#2F327D",
//           }}
//         >
//           Sessions Pending: -
//         </Typography>
//       </Box>
//       {/* first session component */}
//       {/* */}
//       {newdata.length === 0 && !loading && (
//         <p style={{ marginTop: "20px", fontWeight: "bold" }}>
//           {" "}
//           No Data to display
//         </p>
//       )}
//       {newdata.length === 0 && loading && (
//         <>
//           <Box
//             style={{
//               width: "55.313rem",
//               boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
//               borderRadius: "10px",
//               padding: "10px 20px ",
//               marginTop: "20px",
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <Box>
//               <Typography
//                 sx={{
//                   maxWidth: "3rem",
//                   fontFamily: "poppins",
//                   fontWeight: "600",
//                   fontSize: "0.75rem",
//                   fontStyle: "normal",
//                   color: "#2F327D",
//                   padding: "0.438  1.438rem",
//                 }}
//               >
//                 Student
//               </Typography>
//               <Skeleton variant="rectangular" width={200} height={38} />
//             </Box>

//             <Box>
//               <Skeleton sx={{ width: "7rem", height: "2rem" }} />
//             </Box>

//             <Box>
//               <Skeleton variant="rectangular" width={100} height={38} />
//             </Box>
//           </Box>

//           <Box
//             style={{
//               width: "55.313rem",
//               boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
//               borderRadius: "10px",
//               padding: "10px 20px ",
//               marginTop: "20px",
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <Box>
//               <Typography
//                 sx={{
//                   maxWidth: "3rem",
//                   fontFamily: "poppins",
//                   fontWeight: "600",
//                   fontSize: "0.75rem",
//                   fontStyle: "normal",
//                   color: "#2F327D",
//                   padding: "0.438  1.438rem",
//                 }}
//               >
//                 Student
//               </Typography>
//               <Skeleton variant="rectangular" width={200} height={38} />
//             </Box>

//             <Box>
//               <Skeleton sx={{ width: "7rem", height: "2rem" }} />
//             </Box>

//             <Box>
//               <Skeleton variant="rectangular" width={100} height={38} />
//             </Box>
//           </Box>

//           <Box
//             style={{
//               width: "55.313rem",
//               boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
//               borderRadius: "10px",
//               padding: "10px 20px ",
//               marginTop: "20px",
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <Box>
//               <Typography
//                 sx={{
//                   maxWidth: "3rem",
//                   fontFamily: "poppins",
//                   fontWeight: "600",
//                   fontSize: "0.75rem",
//                   fontStyle: "normal",
//                   color: "#2F327D",
//                   padding: "0.438  1.438rem",
//                 }}
//               >
//                 Student
//               </Typography>
//               <Skeleton variant="rectangular" width={200} height={38} />
//             </Box>

//             <Box>
//               <Skeleton sx={{ width: "7rem", height: "2rem" }} />
//             </Box>

//             <Box>
//               <Skeleton variant="rectangular" width={100} height={38} />
//             </Box>
//           </Box>

//           <Box
//             style={{
//               width: "55.313rem",
//               boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
//               borderRadius: "10px",
//               padding: "10px 20px ",
//               marginTop: "20px",
//               display: "flex",
//               justifyContent: "space-between",
//               alignItems: "center",
//             }}
//           >
//             <Box>
//               <Typography
//                 sx={{
//                   maxWidth: "3rem",
//                   fontFamily: "poppins",
//                   fontWeight: "600",
//                   fontSize: "0.75rem",
//                   fontStyle: "normal",
//                   color: "#2F327D",
//                   padding: "0.438  1.438rem",
//                 }}
//               >
//                 Student
//               </Typography>
//               <Skeleton variant="rectangular" width={200} height={40} />
//             </Box>

//             <Box>
//               <Skeleton sx={{ width: "7rem", height: "2rem" }} />
//             </Box>

//             <Box>
//               <Skeleton variant="rectangular" width={100} height={45} />
//             </Box>
//           </Box>
//         </>
//       )}{" "}
//       {newdata.map((info) => {
//         const abc = info.date;
//         const mynewmonth = gettingmonth(abc);
//         const mymonth = mynewmonth[0];
//         const mydate = mynewmonth[1];
//         const myyear = mynewmonth[2];
//         const firstname = info.user.first_name;
//         const lastname = info.user.last_name;
//         const sessionsttime = info.slot.time_slot.commence;

//         return (
//           <SessionComp
//             slotId={info.slot.id}
//             firstname={firstname}
//             lastname={lastname}
//             sessionsttime={sessionsttime}
//             mymonth={mymonth}
//             mydate={mydate}
//             myyear={myyear}
//             checkDrawerClick = {checkDrawerClick}
//           />
//         );
//       })}
//       {/* end here  */}
//     </>
//   );
// }

// second code

import { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { Button, CardActionArea, CardActions, Box, Stack } from "@mui/material";
import BoxShadowC from "../../Components/BoxShadowC/BoxShadowC";
import styles from "./dashboard.module.css";
import axios from "axios";
import { token_api } from "../../Utils/Network";
import { FormatColorResetOutlined, Info } from "@material-ui/icons";
import { Skeleton } from "@mui/material";
import SessionComp from "../../Components/SessionComp/SessionComp";
import { gettingmonth } from "../../Utils/Utils";
import { Fakedata } from "../../Components/Fakedata/Fakedata";
import { toBeEmptyDOMElement } from "@testing-library/jest-dom/dist/matchers";
import NOTIFY from "../../Assets/notify.wav";

// import { getToken as getFcmId, onMessageListener } from "../../Utils/firebase";

export default function BoxSx() {
  // const [fcmId, setFcmId] = useState("");
  const audio = new Audio(NOTIFY);

  // const token = localStorage.getItem("lcarro_user_creds_context");
  // console.log("token on", token);

  // useEffect(() => {
  //   if (fcmId) {
  //     // registerUser();
  //   } else {
  //     getFcmId(setFcmId);
  //     console.log("ter", fcmId);
  //   }
  // }, [fcmId]);

  // async function requestPermission() {
  //   const permission = await Notification.requestPermission();
  //   if(permission === 'granted'){
  //     //Generate Token
  //     const token = await getToken(messaging, {vapidKey: 'BAn4OKqgPchINoq_S912j91RRKefN5Mlsls3qygGC-15asGFzugi5Um5vS2gUBHZc01-88-9BGsN9p3u4iLyzUA'});
  //     console.log("Token Gen", token);
  //   }else if (permission === 'denied'){
  //     alert("You denied for the notification")
  //   }
  // }

  // useEffect(() => {
  //   if (token)
  //     if (fcmId) {
  //       registerUser();
  //     } else getFcmId(setFcmId);
  // }, [fcmId]);

  // const registerUser = () => {
  //   const user = localStorage.getItem("user");
  //   // if (!getLocalData("fcmId") && user)
  //   token_api
  //     .post(`/notification/fcm_register/`, {
  //       device_id: "12",
  //       registration_id: fcmId,
  //       type: "web",
  //       user: user,
  //     })
  //     .then((r) => console.log("eeeee", r.data.data))
  //     .catch((e) => console.log(e, "wwwwww"));
  // };

  // onMessageListener()
  //   .then((payload) => {
      
  //       audio.play();
  //     } ).catch((err) => console.log("failed: ", err));

  // console.log(fcmId, "cd");

  const [newdata, setnewData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checkDrawerClick, setCheckclick] = useState(true);

  const fetchSessions = async () => {
    setLoading(true);
    try {
      const response = await token_api.get(
        "/counseling/v2/all_slots/allocated_instructor_slot"
      );
      //  (response);
      setnewData(response.data.data);
      setLoading(false);
      console.log(newdata);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchSessions();
  }, []);

  const abc = false;
  return (
    <>
      <Box sx={{ display: "flex", flexWrap: "wrap", gap: 4 }}>
        <BoxShadowC>
          <Box
            sx={{
              width: "26rem",
              borderRadius: 2,
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "1.125rem",
                lineHeight: "27px",
                color: "#2F327D",
                textAlign: "center",
                mt: "1.063rem",
              }}
            >
              Today's Counselling Sessions
            </Typography>

            <Typography
              className={styles.mynumbers}
              sx={{
                fontFamily: "Poppins",
                fontSize: "2.5rem",
                fontWeight: 600,
                mt: "0.65rem",
                color: "#2F327D",
                textAlign: "center",
              }}
            >
              -/-
            </Typography>

            <Box className={styles.bottomcon} direction="row">
              <Typography
                className={styles.mynumbers}
                sx={{
                  textAlign: "center",
                  color: "#2F327D",
                  width: "2rem",

                  fontSize: "0.75rem",
                  fontWeight: 550,
                  mt: "0.188rem",
                }}
              >
                Done
              </Typography>

              <Typography
                className={styles.mynumbers}
                sx={{
                  textAlign: "center",
                  color: "#2F327D",
                  width: "2rem",
                  fontSize: "0.75rem",
                  fontWeight: 550,
                  mt: "0.188rem",
                }}
              >
                All
              </Typography>
            </Box>
          </Box>
        </BoxShadowC>

        {/* second components */}
        <BoxShadowC>
          <Box
            sx={{
              width: "26rem",
              borderRadius: 2,
            }}
          >
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                fontFamily: "Poppins",
                fontWeight: "600",
                fontSize: "1.125rem",
                lineHeight: "27px",
                color: "#2F327D",
                textAlign: "center",
                mt: "1.063rem",
              }}
            >
              This Month Counselling Sessions
            </Typography>

            <Typography
              className={styles.mynumbers}
              sx={{
                fontFamily: "Poppins",
                fontSize: "2.5rem",
                fontWeight: 600,
                mt: "0.65rem",
                color: "#2F327D",
                textAlign: "center",
              }}
            >
              -/-
            </Typography>

            <Box className={styles.bottomcon} direction="row">
              <Typography
                className={styles.mynumbers}
                sx={{
                  textAlign: "center",
                  color: "#2F327D",
                  width: "2rem",
                  fontSize: "0.75rem",
                  fontWeight: 550,
                  mt: "0.188rem",
                }}
              >
                Done
              </Typography>

              <Typography
                className={styles.mynumbers}
                sx={{
                  textAlign: "center",
                  color: "#2F327D",
                  width: "2rem",
                  fontSize: "0.75rem",
                  fontWeight: 550,
                  mt: "0.188rem",
                }}
              >
                All
              </Typography>
            </Box>
          </Box>
        </BoxShadowC>
      </Box>
      {/* top component end here */}
      <Box>
        <Typography
          className={styles.mynumbers}
          sx={{
            maxWidth: "11.563rem",
            fontFamily: "poppins",
            fontSize: "1.125rem",
            mt: "2.5rem",
            mb: "2rem",
            fontWeight: "600",
            color: "#2F327D",
          }}
        >
          Sessions Pending: -
        </Typography>
      </Box>
      {/* first session component */}
      {/* */}
      {newdata.length === 0 && !loading && (
        <p style={{ marginTop: "20px", fontWeight: "bold" }}>
          {" "}
          No Data to display
        </p>
      )}
      {newdata.length === 0 && loading && (
        <>
          <Box
            style={{
              width: "55.313rem",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              padding: "10px 20px ",
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                sx={{
                  maxWidth: "3rem",
                  fontFamily: "poppins",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                  fontStyle: "normal",
                  color: "#2F327D",
                  padding: "0.438  1.438rem",
                }}
              >
                Student
              </Typography>
              <Skeleton variant="rectangular" width={200} height={38} />
            </Box>

            <Box>
              <Skeleton sx={{ width: "7rem", height: "2rem" }} />
            </Box>

            <Box>
              <Skeleton variant="rectangular" width={100} height={38} />
            </Box>
          </Box>

          <Box
            style={{
              width: "55.313rem",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              padding: "10px 20px ",
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                sx={{
                  maxWidth: "3rem",
                  fontFamily: "poppins",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                  fontStyle: "normal",
                  color: "#2F327D",
                  padding: "0.438  1.438rem",
                }}
              >
                Student
              </Typography>
              <Skeleton variant="rectangular" width={200} height={38} />
            </Box>

            <Box>
              <Skeleton sx={{ width: "7rem", height: "2rem" }} />
            </Box>

            <Box>
              <Skeleton variant="rectangular" width={100} height={38} />
            </Box>
          </Box>

          <Box
            style={{
              width: "55.313rem",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              padding: "10px 20px ",
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                sx={{
                  maxWidth: "3rem",
                  fontFamily: "poppins",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                  fontStyle: "normal",
                  color: "#2F327D",
                  padding: "0.438  1.438rem",
                }}
              >
                Student
              </Typography>
              <Skeleton variant="rectangular" width={200} height={38} />
            </Box>

            <Box>
              <Skeleton sx={{ width: "7rem", height: "2rem" }} />
            </Box>

            <Box>
              <Skeleton variant="rectangular" width={100} height={38} />
            </Box>
          </Box>

          <Box
            style={{
              width: "55.313rem",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.2)",
              borderRadius: "10px",
              padding: "10px 20px ",
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Box>
              <Typography
                sx={{
                  maxWidth: "3rem",
                  fontFamily: "poppins",
                  fontWeight: "600",
                  fontSize: "0.75rem",
                  fontStyle: "normal",
                  color: "#2F327D",
                  padding: "0.438  1.438rem",
                }}
              >
                Student
              </Typography>
              <Skeleton variant="rectangular" width={200} height={40} />
            </Box>

            <Box>
              <Skeleton sx={{ width: "7rem", height: "2rem" }} />
            </Box>

            <Box>
              <Skeleton variant="rectangular" width={100} height={45} />
            </Box>
          </Box>
        </>
      )}{" "}
      {newdata.map((info) => {
        const abc = info.date;
        const mynewmonth = gettingmonth(abc);
        const mymonth = mynewmonth[0];
        const mydate = mynewmonth[1];
        const myyear = mynewmonth[2];
        const firstname = info.user.first_name;
        const lastname = info.user.last_name;
        const sessionsttime = info.slot.time_slot.commence;

        return (
          <SessionComp
            slotId={info.slot.id}
            firstname={firstname}
            lastname={lastname}
            sessionsttime={sessionsttime}
            mymonth={mymonth}
            mydate={mydate}
            myyear={myyear}
            checkDrawerClick={checkDrawerClick}
          />
        );
      })}
      {/* end here  */}
    </>
  );
}
