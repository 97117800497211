import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  FormLabel,
  Typography,
  Dialog,
  InputLabel,
  DialogContentText,
} from "@mui/material";
import PropTypes from "prop-types";
import CloseIcon from "@mui/icons-material/Close";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";
import { token_api } from "../../Utils/Network";
import styles from "./students.module.css";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import CustomSnackBar from "../../Components/CustomSnackBar/CustomSnackBar";
import { session_notesFake } from "../../Components/Fakedata/Fakedata";
import styled from "@emotion/styled";
import BookCounsellingSlots from "./BookCounsellingSlot";
import moment from "moment";
import { paymentsection } from "../../Utils/Constants";
import { findPayObject } from "../../Utils/Utils";
// for book counselling slot
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function EditFieldsForm({
  handleClose,
  editSection,
  editFormDetails,
  objForForm,
  ...props
}) {
  const [openBook, setOpenBook] = React.useState(false);
  // const [opensecond , setOpenSecond] = useState(false)

  const [secondopen, setsecondOpen] = useState(false);
  const { gradeList } = useContext(UserCredsContext);
  const { instituteList } = useContext(UserCredsContext);
  console.log("ins list", instituteList);

  const [noCash, setNoCash] = useState(false);

  const [dates, setDates] = useState([]);
  const [paymentform, setPaymentForm] = useState({});

  const [userObject, setUserObject] = useState({
    first_name: "",
    last_name: "",
    gender: 0,
    board: null,
    grade: null,
    title: "",
    dob: "",
    title: "",
    institute: null,
    phone: "",
  });
  const [addressObject, setAddressObject] = useState({
    id: null,
    line_1: "",
    line_2: "",
    landmark: "",
    city: "",
    zipcode: "",
    primary: true,
    state: "",
    country: "",
  });
  const [guardianObject, setGaurdianObject] = useState({
    id: null,
    first_name: "",
    last_name: "",
    phone: "",
    email: "",
    relationship: null,
    occupation: "",
    education: "",
    dob: "",
  });
  const [sessionObject, setSessionObject] = useState({
    id: null,
    language: "",
    dream: "",
    reason_for_referral: "",
    session_notes: {
      session_field1: "",
    },
  });

  const getSavedData = (key) => {
    switch (key) {
      case "guardian": {
        return [guardianObject];
      }
      case "address": {
        return [addressObject];
      }

      default:
        return;
    }
  };

  const handleSubmit = () => {
    var data = {};

    if (editSection.id === 1) {
      data = {
        user_id: objForForm.id,
        user: {
          first_name: userObject.first_name,
          last_name: userObject.last_name,
          gender: userObject.gender,
        },
        profile: {
          board_id: userObject.board,
          grade_id: userObject.grade,
          title: userObject.title,
          dob: userObject.dob,
          institute: userObject.institute,
        },
      };
    } else if (editSection.id === 5) {
      var size = Object.keys(session_notesFake).length;

      data = {
        user_id: objForForm.id,
        session: [
          {
            id: sessionObject.id,
            session_notes: {
              "session_field`${size}`": "session_field`${size}`",
            },
          },
        ],
      };
    } else {
      data = {
        user_id: objForForm.id,
      };

      data[editSection.key] = getSavedData(editSection.key);
    }

    console.log("dddd", data);

    token_api
      .post(`/counseling/v2/all_slots/update_student_profile/`, data)
      .then((response) => {
        console.log("Checking Response after post", response.data.data);
      });
    handleClose();
  };

  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  const handleDate = (date, _key) => {
    const [dateString] = date.toISOString().split("T");

    switch (_key) {
      case "userObject": {
        setUserObject({ ...userObject, dob: dateString });
      }

      case "guardianObject": {
        setGaurdianObject({ ...guardianObject, dob: dateString });
      }
      default:
        return;
    }
  };

  const handleChange = (e, _key) => {
    const { name, value } = e.target;

    switch (_key) {
      case "userObject": {
        return setUserObject({ ...userObject, [name]: value });
      }

      case "addressObject": {
        return setAddressObject({ ...addressObject, [name]: value });
      }

      case "guardianObject": {
        return setGaurdianObject({ ...guardianObject, [name]: value });
      }

      case "sessionObject": {
        return setSessionObject({ ...sessionObject, [name]: value });
      }
      // case "sessionNotesObject": {

      // }
      default:
        return;
    }

    // var newObject = { ...editObject };
    // newObject[_key][name] = value;

    // setEditObject({...newObject})
  };

  console.log("User222", userObject);
  console.log("SessionOb", sessionObject);

  const handleSaveBook = (e, string) => {
    // setOpenBook(true);

    const retObj = findPayObject(paymentsection, string, "type");
    console.log("retpay", retObj);
    setPaymentForm(retObj);

    console.log("pp");
    setsecondOpen(true);
  };
  console.log("yyy", openBook);

  const [payment, setPayment] = React.useState("");

  const handleChangePayment = (event) => {
    setPayment(event.target.value);
    console.log("pay", event.target.value);
    if (event.target.value === "cash") {
      setNoCash(false);
    } else {
      setNoCash(true);
    }
  };

  // const createCounsellingSession = () => {
  //   return (
  //     <>
  //       <div>
  //         <Typography
  //           sx={{
  //             fontSize: "20px",
  //           }}
  //         >
  //           Student Details
  //         </Typography>
  //         <Grid container spacing={2}>
  //           <Grid item xs={6}>
  //             <TextField
  //               id="outlined-required"
  //               label="First Name"
  //               size="small"
  //               name="first_name"
  //               // value={userObject.first_name}
  //               // onChange={(e) => handleChange(e, "userObject")}
  //             />
  //           </Grid>
  //           <Grid item xs={6}>
  //             <TextField
  //               id="outlined-required"
  //               label="Last Name"
  //               size="small"
  //               name="last_name"
  //               // value={userObject.last_name}
  //               // onChange={(e) => handleChange(e, "userObject")}
  //             />
  //           </Grid>

  //           <Grid item xs={6}>
  //             <TextField
  //               id="outlined-required"
  //               label="Phone"
  //               size="small"
  //               name="phone"
  //               // value={guardianObject.phone}
  //               // onChange={(e) => handleChange(e, "guardianObject")}
  //             />
  //           </Grid>

  //           <Grid item xs={6}>
  //             <TextField
  //               id="outlined-required"
  //               label="Email"
  //               size="small"
  //               name="email"
  //               // value={guardianObject.email}
  //               // onChange={(e) => handleChange(e, "guardianObject")}
  //             />
  //           </Grid>

  //           <Grid item xs={6}>
  //             <Select
  //               labelId="demo-simple-select-disabled-label"
  //               id="demo-simple-select-disabled"
  //               // value={userObject.grade}
  //               label="grade"
  //               name="grade"
  //               // onChange={(e) => handleChange(e, "userObject")}
  //               style={{ width: "90%" }}
  //               size="small"
  //             >
  //               {gradeList.map((item, idx) => {
  //                 return (
  //                   <MenuItem value={item.id}>
  //                     <em>{item.title}</em>
  //                   </MenuItem>
  //                 );
  //               })}
  //             </Select>
  //           </Grid>

  //           <Grid item xs={6}>
  //             <Select
  //               labelId="demo-simple-select-disabled-label"
  //               id="demo-simple-select-disabled"
  //               // value={userObject.institute}
  //               label="Institute"
  //               name="institute"
  //               // onChange={(e) => handleChange(e, "userObject")}
  //               style={{ width: "90%" }}
  //               size="small"
  //             >
  //               {instituteList.map((item, idx) => {
  //                 return (
  //                   <MenuItem value={item.id}>
  //                     <em>{item.name}</em>
  //                   </MenuItem>
  //                 );
  //               })}
  //             </Select>
  //           </Grid>
  //         </Grid>
  //       </div>

  //       {/* 2nd section */}

  //       <div>
  //         <Typography
  //           sx={{
  //             fontSize: "20px",
  //           }}
  //         >
  //           Guardian Information
  //         </Typography>

  //         <Grid container spacing={2}>
  //           <Grid item xs={6}>
  //             <TextField
  //               id="outlined-required"
  //               label="First Name"
  //               size="small"
  //               name="first_name"
  //               // value={guardianObject.first_name}
  //               // onChange={(e) => handleChange(e, "guardianObject")}
  //             />
  //           </Grid>
  //           <Grid item xs={6}>
  //             <TextField
  //               id="outlined-required"
  //               label="Last Name"
  //               size="small"
  //               name="last_name"
  //               Change
  //               // value={guardianObject.last_name}
  //               // onChange={(e) => handleChange(e, "guardianObject")}
  //             />
  //           </Grid>
  //           <Grid item xs={6}>
  //             <TextField
  //               id="outlined-required"
  //               label="Phone"
  //               size="small"
  //               name="phone"
  //               // value={guardianObject.phone}
  //               // onChange={(e) => handleChange(e, "guardianObject")}
  //             />
  //           </Grid>

  //           <Grid item xs={6}>
  //             <TextField
  //               id="outlined-required"
  //               label="Email"
  //               size="small"
  //               name="email"
  //               // value={guardianObject.email}
  //               // onChange={(e) => handleChange(e, "guardianObject")}
  //             />
  //           </Grid>

  //           <Grid item xs={6}>
  //             <TextField
  //               id="outlined-required"
  //               label="Occupation"
  //               size="small"
  //               name="occupation"
  //               // value={guardianObject.occupation}
  //               // onChange={(e) => handleChange(e, "guardianObject")}
  //             />
  //           </Grid>

  //           <Grid item xs={6}>
  //             <TextField
  //               id="outlined-required"
  //               label="Education"
  //               size="small"
  //               name="education"
  //               // value={guardianObject.education}
  //               // onChange={(e) => handleChange(e, "guardianObject")}
  //             />
  //           </Grid>

  //           <Grid item xs={6}>
  //             <TextField
  //               id="outlined-required"
  //               label="Relation"
  //               size="small"
  //               name="Relation"
  //               // value={guardianObject.education}
  //               // onChange={(e) => handleChange(e, "guardianObject")}
  //             />
  //           </Grid>
  //         </Grid>
  //       </div>

  //       {/* 3rd section */}

  //       <div>
  //         <Typography>Book Counselling Slot</Typography>
  //         <div>
  //           <Typography>
  //             <Button>Now</Button>
  //           </Typography>

  //           <Typography>
  //             <DatePicker
  //               // selected={filterData.date}
  //               placeholderText="Date"
  //               dateFormat="dd/MM/yyyy"
  //               // onChange={(date) => handleDateAddStudent(date)}
  //             />
  //           </Typography>
  //           {dates &&
  //             dates.map((data, idx) => (
  //               <Typography key={idx}>
  //                 <Button>
  //                   {moment(data.commence, "HH:mm:ss ").format("hh:mm A")}
  //                 </Button>
  //               </Typography>
  //             ))}
  //         </div>
  //       </div>

  //       {/* payment */}

  //       <div>
  //         <Typography>
  //           Payment Details :{" "}
  //           <FormControl fullWidth>
  //             <InputLabel id="demo-simple-select-label">Cash</InputLabel>
  //             <Select
  //               labelId="demo-simple-select-label"
  //               id="demo-simple-select"
  //               value={payment}
  //               label={payment}
  //               onChange={handleChangePayment}
  //             >
  //               <MenuItem value={"upi"}>UPI</MenuItem>
  //               <MenuItem value={"neft"}>NEFT</MenuItem>
  //               <MenuItem value={"cheque"}>Cheque</MenuItem>
  //             </Select>
  //           </FormControl>
  //         </Typography>
  //       </div>

  //       {noCash ? (
  //         <div>
  //           <Typography>
  //             Transaction Id :{" "}
  //             <TextField
  //               id="outlined-basic"
  //               label="Transaction Id"
  //               variant="outlined"
  //             />
  //           </Typography>
  //         </div>
  //       ) : (
  //         ""
  //       )}
  //     </>
  //   );
  // };

  const addressDetails = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="outlined-required"
            label="Landmark"
            size="small"
            name="landmark"
            // value={addressObject.landmark}
            // onChange={(e) => handleChange(e, "addressObject")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-required"
            label="Pincode"
            size="small"
            name="zipcode"
            // value={addressObject.zipcode}
            // onChange={(e) => handleChange(e, "addressObject")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-required"
            label="City"
            size="small"
            name="city"
            // value={addressObject.city}
            // onChange={(e) => handleChange(e, "addressObject")}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-required"
            label="State"
            size="small"
            name="state"
            // value={addressObject.state}
            // onChange={(e) => handleChange(e, "addressObject")}
          />
        </Grid>
      </Grid>
    );
  };

  const guardianDetails = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="outlined-required"
            label="First Name"
            size="small"
            name="first_name"
            value={guardianObject.first_name}
            onChange={(e) => handleChange(e, "guardianObject")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-required"
            label="Last Name"
            size="small"
            name="last_name"
            Change
            value={guardianObject.last_name}
            onChange={(e) => handleChange(e, "guardianObject")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-required"
            label="Phone"
            size="small"
            name="phone"
            value={guardianObject.phone}
            onChange={(e) => handleChange(e, "guardianObject")}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-required"
            label="Email"
            size="small"
            name="email"
            value={guardianObject.email}
            onChange={(e) => handleChange(e, "guardianObject")}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-required"
            label="Occupation"
            size="small"
            name="occupation"
            value={guardianObject.occupation}
            onChange={(e) => handleChange(e, "guardianObject")}
          />
        </Grid>

        <Grid item xs={6}>
          <TextField
            id="outlined-required"
            label="Education"
            size="small"
            name="education"
            value={guardianObject.education}
            onChange={(e) => handleChange(e, "guardianObject")}
          />
        </Grid>
      </Grid>
    );
  };

  const sessionNotesEdit = () => {
    var size = Object.keys(session_notesFake).length;
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="outlined-multiline-static"
            label="Session notes"
            size="small"
            name="session_notes"
            multiline
            rows={4}
            value={sessionObject.session_notes}
            onChange={(e) => handleChange(e, "sessionObject")}
          />
        </Grid>
      </Grid>
    );
  };

  const sessionDetails = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="outlined-required"
            label="Language"
            size="small"
            name="language"
            value={sessionObject.language}
            onChange={(e) => handleChange(e, "sessionObject")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-required"
            label="Dream"
            size="small"
            name="dream"
            value={sessionObject.dream}
            onChange={(e) => handleChange(e, "sessionObject")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-multiline-static"
            label="Session notes"
            size="small"
            name="session_notes"
            multiline
            rows={4}
            value={sessionObject.session_notes}
            onChange={(e) => handleChange(e, "sessionObject")}
          />
        </Grid>
      </Grid>
    );
  };

  const userDetails = () => {
    return (
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <TextField
            id="outlined-required"
            label="Title"
            size="small"
            name="title"
            value={userObject.title}
            onChange={(e) => handleChange(e, "userObject")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-required"
            label="First Name"
            size="small"
            name="first_name"
            value={userObject.first_name}
            onChange={(e) => handleChange(e, "userObject")}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            id="outlined-required"
            label="Last Name"
            size="small"
            name="last_name"
            value={userObject.last_name}
            onChange={(e) => handleChange(e, "userObject")}
          />
        </Grid>
        <Grid item xs={6}>
          <Select
            labelId="demo-simple-select-disabled-label"
            id="demo-simple-select-disabled"
            value={userObject.grade}
            label="grade"
            name="grade"
            onChange={(e) => handleChange(e, "userObject")}
            style={{ width: "90%" }}
            size="small"
          >
            {gradeList.map((item, idx) => {
              return (
                <MenuItem value={item.id}>
                  <em>{item.title}</em>
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <Select
            labelId="demo-simple-select-disabled-label"
            id="demo-simple-select-disabled"
            value={userObject.institute}
            label="Institute"
            name="institute"
            onChange={(e) => handleChange(e, "userObject")}
            style={{ width: "90%" }}
            size="small"
          >
            {instituteList.map((item, idx) => {
              return (
                <MenuItem value={item.id}>
                  <em>{item.name}</em>
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Grid item xs={6}>
          <FormControl>
            <FormLabel id="demo-radio-buttons-group-label">Gender</FormLabel>
            <RadioGroup
              aria-labelledby="demo-radio-buttons-group-label"
              defaultValue="female"
              name="gender"
            >
              <FormControlLabel
                value="1"
                control={<Radio />}
                label="Female"
                onChange={(e) => handleChange(e, "userObject")}
              />
              <FormControlLabel
                value="2"
                control={<Radio />}
                label="Male"
                onChange={(e) => handleChange(e, "userObject")}
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={6}>
          <DatePicker
            name="dob"
            value={userObject.dob}
            dateFormat="dd/MM/yyyy"
            onChange={(date) => handleDate(date, "userObject")}
          />
        </Grid>
      </Grid>
    );
  };

  useEffect(() => {
    if (objForForm === "undefined") {
      return;
    } else {
      if (editSection.id === 1) {
        const { first_name, last_name, profile, gender, phone } = objForForm;
        console.log("iiii", profile);

        setUserObject({
          ...userObject,
          grade: profile.grade?.id,
          title: profile.title,
          dob: profile.dob,
          first_name,
          last_name,
          title: profile.title,
          gender,
          institute: profile.institute,
          phone,
        });
      }

      if (editSection.id === 2) {
        const { guardian } = objForForm;

        const editGuardianArray = guardian.find((gElement) => {
          return gElement.id == editFormDetails.id;
        });

        setGaurdianObject({
          ...guardianObject,
          id: editFormDetails.id,
          first_name: editGuardianArray.first_name,
          last_name: editGuardianArray.last_name,

          phone: editGuardianArray.phone,
          email: editGuardianArray.email,
          relationship: editGuardianArray.relationship,
          occupation: editGuardianArray.occupation,
          education: editGuardianArray.education,
          dob: editGuardianArray.dob,
        });
      }

      if (editSection.id === 3) {
        const { address } = objForForm;

        const editAddressArray = address.find((addressElement) => {
          return addressElement?.id == editFormDetails?.id;
        });

        setAddressObject({
          ...addressObject,
          id: editAddressArray.id,
          landmark: editAddressArray.landmark,
          zipcode: editAddressArray.zipcode,
          city: editAddressArray.city,
          state: editAddressArray.state,
        });
      }

      if (editSection.id === 5) {
        const { sessions } = objForForm;

        const editSessionarray = sessions.find((sesElement) => {
          return sesElement.id == editFormDetails.id;
        });

        console.log(editSessionarray, "sss123");

        setSessionObject({
          ...sessionObject,
          id: editSessionarray.id,
          dream: editSessionarray.dream,
          language: editSessionarray.language,
          session_notes: editSessionarray.session_notes,
        });
      }
    }
  }, [objForForm]);

  useEffect(() => {
    token_api
      .get(`/counseling/all_slots/slots/?date=16-09-2022`)
      .then((response) => {
        setDates(response.data.data);
      });
  }, []);

  return (
    <React.Fragment>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        {editSection.id == 1 && (
          <p className={styles.editStyle}> User Details</p>
        )}
        {editSection.id == 2 && (
          <p className={styles.editStyle}> Guardian Info</p>
        )}
        {editSection.id == 3 && (
          <p className={styles.editStyle}> Address Info</p>
        )}

        {editSection.id == 4 && (
          <p className={styles.editStyle}> Session Details</p>
        )}

        {editSection.id == 5 && (
          <p className={styles.editStyle}>Session Notes</p>
        )}

        {editSection.id == 6 && (
          <p className={styles.editStyle}>Create Couselling Slot</p>
        )}
      </BootstrapDialogTitle>
      <DialogContent dividers>
        {editSection.id == 1 && userDetails()}
        {editSection.id == 2 && guardianDetails()}
        {editSection.id == 3 && addressDetails()}

        {editSection.id == 4 && sessionDetails()}
        {editSection.id == 5 && sessionNotesEdit()}
        {/* {editSection.id == 6 && createCounsellingSession()} */}

        {/* {sessionDetails()} */}
        {/* {userDetails()} */}
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleSubmit}>
          <p className={styles.editStyle}>Save</p>
        </Button>

        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>

      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        openBook={openBook}
      >
        <BookCounsellingSlots />
      </BootstrapDialog>

     
    </React.Fragment>
  );
}

export default EditFieldsForm;

const editSectionList = [
  {
    type: "userDetails",
    id: 1,
  },
  {
    type: "guardianDetails",
    id: 2,
    key: "guardian",
  },
  {
    type: "addressDetails",
    id: 3,
  },
  {
    type: "sessionDetails",
    id: 4,
  },
];
