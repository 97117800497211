import { Divider } from "@mui/material";
import React, {useContext} from "react";
import BoxShadowC from "../../Components/BoxShadowC/BoxShadowC";
import GoogleLogin from "../../Components/GoogleLogin/GoogleLogin";
import styles from "./Login.module.css";
import LoginForm from "./LoginForm/LoginForm";
import lcarroLogo from "../../Assets/lcarroLogo.png"
import {DataContext}  from "../../ContextApis/DataContext/DataContext"

function Login() { 

  const  gradeList  = useContext(DataContext);
  

  // console.log(gradeList.theme.palette.primary.main )
  // console.log(gradeList.theme.palette.secondary.main )

  return (
    <div className={styles.loginContainer}>

      <div className={styles.logoStyles}  ><img src={lcarroLogo}  style={{ width : "8.75rem"}} /></div>
      <div className={styles.cardContainer}>
        <BoxShadowC>
          <div className={styles.card}>
            <p className={styles.welComeOne}>Welcome</p>
            <p className={styles.welComeTwo}> Welcome back! Please enter your details.</p>
            
            <GoogleLogin />
            <Divider sx={{marginTop:'20px',marginBottom : "30px" , fontSize:'0.625rem', color:'#00000080'}}>or</Divider>
          </div>
          
          <div>
            <LoginForm />
          </div>
        </BoxShadowC>
      </div>
    </div>
  );
}

export default Login;
