// i am working here.


export const gender = [
    {
      id : 2,
      name: "Female",
    },
    {
      id: 1,
      name:"Male",
    }
  ]

export const paymentsection = [
  {
    type : "payment",
    id :7,
    key:"payment"
  }
]

  export const editSection = [
    {
      type: "userDetails",
      id: 1,
      key: "user",
    },
    {
      type: "guardianDetails",
      id: 2,
      key: "guardian",
    },
    {
      type: "addressDetails",
      id: 3,
      key: "address",
    },
    {
      type: "sessionDetails",
      id: 4,
      key: "sessions",
    },

    {
      type: "sessionNotes",
      id: 5,
      key: "notes",
    },

    {
      type:"bookSlot",
      id:6,
      key:"bookSlot"
    }
  ];