import React, { useState } from "react";
import CustomDatePicker from "../../Components/CustomDatePicker/CustomDatePicker";
import InputField from "../../Components/CustomFields/InputField/InputField";

function TestFields() {
  const [inputValue, setInputValue] = useState({
    name: "",
    last_name: "",
    from_date: "",
  });

//   const handleDate = (_name, _value) => {
//     setFilterData({ ...filterData, [_name]: _value });
//     setApiData({ ...apiData, page: 1 });
//   };

  const handleChange = (_name, _value) => {
    setInputValue({ ...inputValue, [_name]: _value });
  };
  console.log(inputValue, "hello");
  return (
    <div>
      <InputField
        variant="outlined"
        value={inputValue.name}
        onChange={handleChange}
        name="name"
      />
      <InputField
       variant="outlined"
        value={inputValue.last_name}
        onChange={handleChange}
        name="last_name"
      />
      <CustomDatePicker label="From date"
            name="from_date"
            type="register"
            setDate=""
            handleDateChange={handleChange}/>
    </div>
  );
}

export default TestFields;
