import { Button, TextField } from "@mui/material";
import React, { useState } from "react";
import { useEffect } from "react";
import { token_api } from "../../Utils/Network";
import styles from "./sessionNew.module.css";

const CustomSessionNotes = ({ userId, id, session_field,callBack,...props }) => {
  const [sessionNotes, setSessionNotes] = useState("");
  const [card, setCard] = useState(false);
  const handleStudentProfileSubmit = (e) => {
    e.preventDefault();
    let data = {};

    data = {
      user_id: userId,
      session: [
        {
          id: id,
          session_notes: {
            session_field: sessionNotes,
          },
        },
      ],
    };

    token_api
      .post(`/counseling/v2/all_slots/update_student_profile/`, data)
      .then((response) => {
        console.log("Checking Response after post", response.data.data);
        callBack(response.data.data)
      });
    setCard(true);

    console.log("sessionNotes", data);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSessionNotes(value);
  };

  const toggleEdit = () => {
    setCard(false);
  };

  useEffect(()=>{
  setSessionNotes(session_field)
  setCard(true)
  },[session_field])
  return (
    <>
      <div className={styles.cardContainer}>
        <div className={styles.editStyle}>
          <span className={styles.font20}>Session Notes</span>

          <div style={{ display: "flex" }}>
            <span style={{ cursor: "Pointer" }} onClick={toggleEdit}>
              Edit
            </span>
          </div>

          {/* <Button >Speech Stop</Button> */}
          {/* <Button onClick={resetTranscript}>Reset </Button> */}
        </div>
        {card == false ? (
          <>
            <div>
              <form onSubmit={handleStudentProfileSubmit}>
                <TextField
                  fullWidth
                  //   id="outlined-required"
                  //   label="Required"

                  onChange={handleChange}
                  name="session_field"
                  value={sessionNotes}
                  multiline
                  required
                  rows={4}
                />
                <Button style={{position:"relative" ,left:"82%"}} variant="contained" size="small" type="submit">Save</Button>
              </form>
            </div>
          </>
        ) : (
          <>
            <div className={styles.showCard}>
              {sessionNotes && sessionNotes}
            </div>
          </>
        )}

        {/* show card */}
      </div>
    </>
  );
};

export default CustomSessionNotes;
