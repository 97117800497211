import React from 'react';
import styles from "./BoxShadowC.module.css";

function BoxShadowC(props){
    return(
        <div className={styles.cardContainer}>
            {props.children}
        </div>
    )
}

export default BoxShadowC;