import React, { useState } from "react";
import TextField from "@mui/material/TextField";

function InputField({
  variant = "outlined",
  value,
  handleChange,
  label = "",
  name,
  size = "small",
  disabled = false,
  type,
  style,
  id = "standard-multiline-flexible",
  ...props
}) {
  return (
    <React.Fragment>
      <TextField
        id={id}
        label={label}
        variant={variant}
        value={value}
        name={name}
        size={size}
        type={type}
        style={style}
        onChange={handleChange}
      />
    </React.Fragment>
  );
}

export default InputField;
