import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
function BookCounsellingSlots({ handleClose, ...props }) {
  const BootstrapDialogTitle = (props) => {
    const { children, onClose, ...other } = props;

    return (
      <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
        {children}
        {onClose ? (
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        ) : null}
      </DialogTitle>
    );
  };

  BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
  };

  return (
    <React.Fragment>
      <BootstrapDialogTitle id="customized-dialog-title" onClose={handleClose}>
        Book
      </BootstrapDialogTitle>
      <DialogContent dividers>Hii</DialogContent>
      <DialogActions>
        <Button autoFocus onClick={handleClose}>
          Save
        </Button>

        <Button onClick={handleClose}>Cancel</Button>
      </DialogActions>
    </React.Fragment>
  );
}

export default BookCounsellingSlots;
