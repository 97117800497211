import React, { useContext, useEffect, useState } from "react";
import styles from "./index.module.css";
import { Box, Drawer } from "@mui/material";
import { token_api } from "../../Utils/Network";
import { UserCredsContext } from "../../ContextApis/UserCredsContext/UserCredsContext";

const DrawerMentalHealth = ({ open, onClose = () => {}, studentData }) => {
  const { gradeList, instituteList } = useContext(UserCredsContext);
  const [mentalHealth, setMentalHealth] = useState([]);

  console.log(studentData, "DrawerMentalHealth");

  useEffect(() => {
    getMentalhealthData();
  }, [studentData]);

  const getMentalhealthData = () => {
    token_api
      .get(`content/test/listing?user_id=${studentData?.id}`)
    //   .get(`content/test/listing?user_id=8254`)
      .then((res) => {
        // console.log(res?.data, "getMentalhealthData");
        if (res?.status == 200 || res?.status == 201 || res?.status == 204) {
          setMentalHealth(res?.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Drawer anchor="right" open={open} onClose={onClose}>
        <Box className={styles.moreInfo} role="presentation">
          <Box
            sx={{
              width: "90%",
              margin: "auto",
            }}
          >
            <div>
              <h2 className={styles.heading}>Student Details</h2>
              <div className={styles.tagName}>
                <h4 className={styles.tags}>Name</h4>
                <h4 className={styles.profiledata}>
                  {studentData?.first_name} {studentData?.last_name}{" "}
                  <span>{studentData?.id}</span>{" "}
                </h4>
              </div>
              <div className={styles.tagName}>
                <h4 className={styles.tags}>School Name:</h4>
                <h4 className={styles.profiledata}>
                  {
                    instituteList[
                      instituteList.findIndex(
                        (item) => item.id == studentData.profile?.institute
                      )
                    ]?.name
                  }{" "}
                  ,{" "}
                  {instituteList[
                    instituteList.findIndex(
                      (item) => item.id == studentData?.profile?.institute
                    )
                  ]?.location
                    ? instituteList[
                        instituteList.findIndex(
                          (item) => item.id == studentData?.profile?.institute
                        )
                      ]?.location
                    : "-"}
                </h4>
              </div>
              <div className={styles.WhichClass}>
                {studentData?.profile?.grade?.title}
              </div>
              <h2 className={styles.heading}>Mental Health Results</h2>
              {mentalHealth && mentalHealth?.map((test, i) => {
                let totalMarks = 0;
                let outoff = 0;
                test?.test_report?.forEach((item) => {
                  totalMarks += item?.total_marks;
                });

                test?.test_report?.forEach((item) => {
                  outoff += item?.out_off;
                });

                console.log("Total marks:", totalMarks, outoff);
                return (
                  <>
                    <div className={styles.testContainer}>
                      <div className={styles.topLine}>
                        <h3>{test?.title}</h3>
                        <p> {test?.time ? "Test Time" : ""} </p>
                      </div>
                      <div className={styles.midline}>
                        <p className={styles.score}>
                          {" "}
                          <span>Total Score: </span> {totalMarks}/{outoff}
                        </p>
                        <p className={styles.timeshow}> {test?.time ? test?.time : ""}</p>
                      </div>

                      <div>
                        {test?.test_report && test?.test_report?.map((v, i) => {
                          return (
                            <>
                              <div className={styles.parameterContainer}>
                                <div className={styles.leftContainer}>
                                  <h3 className={styles.testCategory}>{v?.category}</h3>
                                  <p className={styles.score}>
                                   
                                    <span>Total Score: </span> {v?.total_marks}/
                                    {v?.out_off}
                                  </p>
                                </div>
                                <div className={styles.rightContainer}>
                                    <h3>{v?.score_type}</h3>
                                </div>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    </div>
                  </>
                );
              })}
            </div>
          </Box>
        </Box>
      </Drawer>
    </>
  );
};

export default DrawerMentalHealth;
