import React, { Component, createContext } from "react";
import { open_api , token_api} from "../../Utils/Network";
import {DataContext}  from "../DataContext/DataContext"
export const UserCredsContext = createContext();

const localstorage_state = localStorage.getItem("lcarro_user_creds_context");

function getLocalData(keyname) {
  // Function to return values from local storage
  let object = null;

  try {
    object = JSON.parse(localstorage_state);
  } catch {
    console.error("There was error while parsing data from localstorage.");
  }

  if (object) {
    if (object[keyname]) {
      return object[keyname];
    }
  }

  if (keyname === "user") return {};
  if (keyname === "token") return { access: "", refresh: "" };

  return "";
}

class UserCredsContextProvider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token: getLocalData("token"),
      user: getLocalData("user"),
      grade: [],
      institute : [],
    };
    this.setUser = this.setUser.bind(this);
    this.setToken = this.setToken.bind(this);
    this.getGradeListing = this.getGradeListing.bind(this);
    this.getInstituteListing = this.getInstituteListing.bind(this);
    this.logout = this.logout.bind(this);
  }

  componentDidMount() {
    this.getGradeListing();
    this.getInstituteListing();
    window.addEventListener("beforeunload", () => {
      localStorage.setItem(
        "lcarro_user_creds_context",
        JSON.stringify(this.state)
      );
    });
  }

  getGradeListing = () => {
    open_api.get(`/base/grade`).then((response) => {
      this.setState({ grade: response.data.data });
    });
  };

  getInstituteListing = () => {
    token_api.get(`/profile/institute/`).then((response) => {
      this.setState({institute: response.data.data})
    })
  }

  setUser(_userData) {
    this.setState({
      user: _userData,
    });
  }

  setToken(_apiToken) {
    this.setState({
      token: _apiToken,
    });
  }

  logout = () => {
    this.setState(
      {
        token: { access: "", refresh: "" },
        user: {},
        grade: [],
      },
      () => {
        localStorage.clear();
        setTimeout(() => {
          window.location.href = "/login";
        }, 500);
      }
    );
  };

  render() {
    const { user, token, grade , institute } = this.state;
    return (
      <UserCredsContext.Provider
        value={{
          user_state: user,
          auth_token: token,
          setToken: this.setToken,
          setUser: this.setUser,
          gradeList: grade,
          instituteList: institute,
          logout: this.logout,
        }}
      >
        {this.props.children}
      </UserCredsContext.Provider>
    );
  }
}

export default UserCredsContextProvider;
