import { Button } from "@mui/material";
import React from "react";
import styles from "./GoogleLogin.module.css";
import googleIcon from "../../Assets/googleIcon.png";

function GoogleLogin() {
  return (
    <div className={styles.loginButtonContainer}>
      <Button style={{ color: "#172b69", background: "white" , padding:'4px' }}>
        <img src={googleIcon} style = {{marginRight : "10px"}}  /> <span>Login with Google</span>
      </Button>
    </div>
  );
}

export default GoogleLogin;
