import { initializeApp } from "firebase/app";
import firebase from 'firebase/compat/app'
import {
  getToken as getFirebaseToken,
  getMessaging,
  onMessage,
} from "firebase/messaging";

import "firebase/compat/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyD3xfKeLAl4CGLRSY2TZuC7Nc73X-SKoLU",
  authDomain: "brainhap-counselling.firebaseapp.com",
  projectId: "brainhap-counselling",
  storageBucket: "brainhap-counselling.appspot.com",
  messagingSenderId: "907089744058",
  appId: "1:907089744058:web:74f69e8c282cfd00777283",
  measurementId: "G-NL72CDBGZQ"
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const  messaging = getMessaging(app);

// export const getToken = (setTokenFound) => {
//   return getFirebaseToken(messaging, {
//     vapidKey:
//       "BL1iJO1xqDqp0zqTlcFq7AsafW0NXElofLpOq1K2dMwntikmI9tkz0bRceXKWT-mPJaTHQxRIL2yC8oZ9w6fRag",
//   })
//     .then((currentToken) => {
//       if (currentToken) {
//         console.log("curr",currentToken);
//         // localStorage.setItem("fid", currentToken )
//         setTokenFound(currentToken);
//       } else {
//         console.log(
//           "No registration token available. Request permission to generate one."
//         );
//         setTokenFound("");
//       }
//     })
//     .catch((err) => {
//       console.log(
//         "An error occurred while retrieving token. ",
//         JSON.stringify(err)
//       );
//     });
// };

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     onMessage(messaging, (payload) => {
//       resolve(payload);
//     });
//   });






// firebase.initializeApp(firebaseConfig);

// const messaging = firebase.messaging();



// export const getToken = async (setTokenFound) => {
//   let currentToken = "";

//   try {
//     currentToken = await messaging.getToken({ vapidKey:"BL1iJO1xqDqp0zqTlcFq7AsafW0NXElofLpOq1K2dMwntikmI9tkz0bRceXKWT-mPJaTHQxRIL2yC8oZ9w6fRag"});
//     if (currentToken) {
//       setTokenFound(true);
//     } else {
//       setTokenFound(false);
//     }
//   } catch (error) {
//     console.log("An error occurred while retrieving token. ", error);
//   }

//   return currentToken;
// };

// export const onMessageListener = () =>
//   new Promise((resolve) => {
//     messaging.onMessage((payload) => {
//       resolve(payload);
//     });
//   });