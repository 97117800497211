import AppRouter from "./Router/AppRouter";
import UserCredsContextProvider from "./ContextApis/UserCredsContext/UserCredsContext";
import DataContextProvider from "./ContextApis/DataContext/DataContext";
import styles from "./App.module.css";
// import { getToken, onMessageListener } from "../src/Utils/firebase";
import { useEffect, useState } from 'react';
import NOTIFY from './Assets/notify.wav'
import Notifications from "./Components/Notifications/Notifications";
import firebase from '../src/Utils/firebase'
import {
  getToken as getFcmId,
 
} from "../src/Utils/firebase";

import {token_api} from './Utils/Network'

function App() {

  
//   const [show, setShow] = useState(false);
//   const [notification, setNotification] = useState({ title: "", body: "" });

//   console.log(show, notification);

//  if(show==true){
//   // console.log("came here");
//   audio.play();

// //   audio.oncanplaythrough = (event) => {
// //     var playedPromise = audio.play();
// //     if (playedPromise) {
// //         playedPromise.catch((e) => {
// //              console.log(e)
// //              if (e.name === 'NotAllowedError' || e.name === 'NotSupportedError') { 
// //                    console.log(e.name);
// //               }
// //          }).then(() => {
// //               console.log("playing sound !!!");
// //          });
// //      }
// // }
//  }
 

  

//   onMessageListener()
//   .then((payload) => {
//     console.log("ppppp",payload);
//     setShow(true);
//     setNotification({
//       title: payload.notification.title,
//       body: payload.notification.body,
//     });
//     console.log(payload,"kj");
//   })
//   .catch((err) => console.log("failed: ", err));





  // const [fcmId, setFcmId] = useState("");
  const audio = new Audio(NOTIFY);

  // useEffect(() => {
  //     if (fcmId) {
  //       // registerUser();
  //     } else getFcmId(setFcmId);
  // }, [fcmId]);

  // // async function requestPermission() {
  // //   const permission = await Notification.requestPermission();
  // //   if(permission === 'granted'){
  // //     //Generate Token
  // //     const token = await getToken(messaging, {vapidKey: 'BAn4OKqgPchINoq_S912j91RRKefN5Mlsls3qygGC-15asGFzugi5Um5vS2gUBHZc01-88-9BGsN9p3u4iLyzUA'});
  // //     console.log("Token Gen", token);
  // //   }else if (permission === 'denied'){
  // //     alert("You denied for the notification")
  // //   }
  // // }

  // useEffect(() => {
  //   //Req user for notification permission
  //   // requestPermission()
  // }, []);

  // onMessageListener()
  //   .then((payload) => {
  //    audio.play()
  //     console.log(payload);
  //   })
  //   .catch((err) => console.log("failed: ", err));



// console.log(fcmId, "cdcdcd");





// code again for app.js in fcm


//  const [fcmId, setFcmId] = useState("");
//  const audio = new Audio(NOTIFY);

//  const token = localStorage.getItem("lcarro_user_creds_context");
// //  console.log("token on", token);

//  useEffect(() => {
//    if (fcmId) {
//      registerUser();
//    } else {
//      getFcmId(setFcmId);
//      console.log("ter", fcmId);
//    }
//  }, [fcmId]);

//  // async function requestPermission() {
//  //   const permission = await Notification.requestPermission();
//  //   if(permission === 'granted'){
//  //     //Generate Token
//  //     const token = await getToken(messaging, {vapidKey: 'BAn4OKqgPchINoq_S912j91RRKefN5Mlsls3qygGC-15asGFzugi5Um5vS2gUBHZc01-88-9BGsN9p3u4iLyzUA'});
//  //     console.log("Token Gen", token);
//  //   }else if (permission === 'denied'){
//  //     alert("You denied for the notification")
//  //   }
//  // }

//  useEffect(() => {
//    if (token)
//      if (fcmId) {
//        registerUser();
//      } else getFcmId(setFcmId);
//  }, [fcmId]);

//  const registerUser = () => {
//    const user = localStorage.getItem("user");
//    // if (!getLocalData("fcmId") && user)

//    if(user){
//     token_api
//     .post(`/notification/fcm_register/`, {
//       device_id: "12",
//       registration_id: fcmId,
//       type: "web",
//       user: user,
//     })
//     .then((r) => console.log("eeeee", r.data.data))
//     .catch((e) => console.log(e, "wwwwww"));

//    }
  
//  };

//  onMessageListener()
//    .then((payload) => {
     
//        audio.play();
//      } ).catch((err) => console.log("failed: ", err));

//  // console.log(fcmId, "cd");




  return (
    <div className={styles.appContainer}>
      <UserCredsContextProvider>
        <DataContextProvider>
          <AppRouter />
        </DataContextProvider>
      </UserCredsContextProvider>
    </div>
  );
}

export default App;
