import { StylesContext } from "@material-ui/styles";
import { TextField } from "@mui/material";
import React, { useContext, useReducer, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserCredsContext } from "../../../ContextApis/UserCredsContext/UserCredsContext";
import { open_api, token_api } from "../../../Utils/Network";
import { DataContext } from "../../../ContextApis/DataContext/DataContext";
import LoginUseReducer from "./LoginUseReducer";
import styles from "./loginStyles.module.css";
import FormControl from "@mui/material/FormControl";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import { useEffect } from "react";
import NOTIFY from "../../../Assets/notify.wav";
import { getMessaging } from "firebase/messaging";
// import {
//   getToken as getFcmId,
//   onMessageListener,
// } from "../../../Utils/firebase";
import { getToken } from "firebase/messaging";
// import firebase from "firebase/compat/app";
import firebase from "../../../Utils/firebase";
import { messaging } from "../../../Utils/firebase";

const initialState = {
  username: "",
  password: "",
  isLoading: false,
  error: "",
  isLoggedIn: false,
};
function LoginForm() {
  const [fcmId, setFcmId] = useState("");
  const [userForFcm, setUserForFcm] = useState("");
  const audio = new Audio(NOTIFY);
  const token = localStorage.getItem("lcarro_user_creds_context");

  const gradeList = useContext(DataContext);

  const primary = gradeList.theme.palette.primary.main;
  const secondary = gradeList.theme.palette.secondary.main;

  const [values, setValues] = React.useState({
    password: "",
  });

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };
  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  let navigate = useNavigate();
  const [state, dispatch] = useReducer(LoginUseReducer, initialState);
  const { username, password, isLoading, error, isLoggedIn } = state;
  const { setToken, setUser } = useContext(UserCredsContext);
  //new way for validation
  const [status, setStatus] = useState(false);

  const [Checkpass, setCheckpass] = useState("");

  const onSubmit = async (event) => {
    setCheckpass("");
    event.preventDefault();

    if (username === "") {
      return;
    }

    const regex =
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const reg = /^\d+$/;
    let Checkmail = false;

    if (reg.test(username)) {
      if (username.length == 10) {
        Checkmail = true;

        setStatus(false);
      } else {
        setStatus(true);
        Checkmail = false;
        return;
      }
    } else {
      if (regex.test(username)) {
        Checkmail = true;
        setStatus(false);
      } else {
        setStatus(true);
        Checkmail = false;
        return;
      }
    }

    const data = {
      username,
      password,
    };
    Checkmail
      ? open_api
          .post(`/auth/user/counselor_login/`, data)
          .then((response) => {
            // console.log(response.data.data);
            const { token, user } = response.data.data;

            setToken(token);
            setUser(user);
            const tokenString = JSON.stringify({ token });

            // console.log("userToken" , response.data.data.user);
            localStorage.setItem("lcarro_user_creds_context", tokenString);
            localStorage.setItem("token", token);
            localStorage.setItem("user", response.data.data.user.id);

            setUserForFcm(response.data.data.user.id);

            // getFcmId(setFcmId);

            // const fff = localStorage.getItem("fid");

            // console.log(fcmId, "fff");

            // const registerUser = () => {
            // const user = localStorage.getItem("user");
            // if (!getLocalData("fcmId") && user)

            if (response.data.data.user.id) {
              handleFcm(response.data.data.user.id);
            }

            console.log(fcmId, "cd");

            navigate("/dashboard/home");
          })
          .catch((error) => {
            alert("Incorrect username password");
          })
      : alert("Incorrect username password");
  };

  const handleFcm = (userID) => {
    console.log("ing", fcmId);

    token_api
      .post(`/notification/fcm_register/`, {
        device_id: "12",
        registration_id: fcmId,
        type: "web",
        user: userID,
      })
      .then((r) => console.log("eeeee", r.data.data))
      .catch((e) => console.log(e, "wwwwww"));
  };

  async function requestPermission() {
    const permission = await Notification.requestPermission();
    if (permission === "granted") {
      //Generate Token

      // getToken(messaging)
      const token = await getToken(messaging, {
        vapidKey:
          "BA17_F8MUXGB0EhMMg924FSQR53z5Zt-3lasyPGIyNj79bNQmFe77FCSd1Vka6uEOLFfEcMgusAwhzjoPZgCG-g",
      });
      console.log("TokenGen", token);
      setFcmId(token)
    } else if (permission === "denied") {
      alert("You denied for the notification");
    }
  }

  useEffect(() => {
    // const messaging = getMessaging()
    // messaging.requestPermission().then(()=>{
    //   return messaging.getToken()
    // }).then(token=>{
    //   console.log("tokem" , token);
    // }).catch(()=>{
    //   console.log("errr");
    // })
   
  }, [])
  

  useEffect(() => {
    //Req user for notification permission
    requestPermission();
  }, []);

  // useEffect(() => {
  //   getFcmId(setFcmId);
  // }, [fcmId]);
  // console.log("fg", fcmId);

  // useEffect(()=>{

  //   }

  // },[fcmId , token])

  // if (fcmId ) {
  //   console.log("hered");

  //   // registerUser();
  //   // setFcmId(fcmId)

  //   console.log("hered1");

  // }
  // else{
  //   getFcmId(setFcmId);
  //   console.log("fid",fcmId);

  // }

  return (
    <div>
      <form className={styles.formBtn} onSubmit={(e) => onSubmit(e)}>
        {error && <p className="error-login">{error}</p>}
        <div className={styles.formstyle}>
          <TextField
            error={status}
            id="standard-basic"
            label="Email"
            variant="standard"
            size="small"
            name="username"
            style={{
              background: "white",
              width: "300px",
              margin: "0 10px",
              marginBottom: "10px ",
            }}
            onChange={(e) =>
              dispatch({
                type: "field",
                fieldName: "username",
                payload: e.currentTarget.value,
              })
            }
            color="primary"
            autoComplete="on"
            value={username}
            helperText={status ? "Please enter a valid Email/Phone Number" : ""}
          />
          <small style={{ color: "red", marginBottom: "10px" }}>
            {Checkpass}
          </small>

          <div className={styles.passbox}>
            <FormControl
              sx={{ width: "300px", marginBottom: "10px " }}
              variant="standard"
            >
              <InputLabel htmlFor="standard-adornment-password">
                Password
              </InputLabel>
              <Input
                error={status}
                helperText={status ? "please enter a valid password" : ""}
                type={values.showPassword ? "text" : "password"}
                id="standard-password-input"
                label="Password"
                variant="standard"
                size="small"
                name="password"
                style={{ background: "white", width: "300px" }}
                onChange={(e) =>
                  dispatch({
                    type: "field",
                    fieldName: "password",
                    payload: e.currentTarget.value,
                  })
                }
                value={password}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
          </div>

          <div className={styles.forgot}>
            <Box sx={{ display: "flex", width: " 500px  " }}>
              <form className={styles.forgotTwo}>
                <Box sx={{ display: "flex", alignItems: "center" }}>
                  <input type="checkbox" placeholder="" />
                  <p>Remember for 30 days</p>
                </Box>

                <Box sx={{ marginLeft: "3.5rem" }}>
                  <p className="text-label-fp">Forgot password</p>
                </Box>
              </form>
            </Box>
          </div>
        </div>
        <div className={styles.loginBtnDiv}>
          <Button
            sx={{
              backgroundColor: { primary },
              width: "18.25rem",
              marginBottom: "20px",
              textTransform: "none",
              "&:hover": {
                background: { secondary },
              },
            }}
            variant="contained"
            disabled={isLoading}
            type="submit"
          >
            {isLoading ? "Logging in..." : "Login"}
          </Button>
        </div>
      </form>
    </div>
  );
}
export default LoginForm;
